import { Helmet } from 'react-helmet-async';
import { useLoading } from '../../utils/contexts/LoadingContext';
import { filter } from 'lodash';
import { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import DynamicSelect from '../../components/select/dynamicSelect';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';

// @mui
import {
  Card, Table, Stack, Paper, Avatar, Button, Popover, Checkbox, TableRow,
  MenuItem, TableBody, TableCell, Container, Typography, IconButton, Divider,
  TableContainer, TablePagination, InputLabel, FormControl, Select, Box, Snackbar, Alert,
  Dialog, DialogTitle, DialogContent, DialogActions, Tooltip, TableHead, FormControlLabel,
  Grid, CardContent
} from '@mui/material';
import { styled } from '@mui/material/styles';
import BarChartIcon from '@mui/icons-material/BarChart';
import CloseIcon from '@mui/icons-material/Close';
import { SvgIcon } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';

// components
import Label from '../../components/label';
import Iconify from '../../components/iconify';
import { ReactComponent as Icon_Attendance } from '../../assets/icons/attendance.svg';
import { ReactComponent as Icon_Payment } from '../../assets/icons/icon_payment.svg';
import { ReactComponent as Icon_Extract } from '../../assets/icons/extract.svg';
import { ReactComponent as Icon_Transaction } from '../../assets/icons/transaction.svg';
import  convertDateTime from '../../utils/convertDateTime';

import Scrollbar from '../../components/scrollbar'
// sections
import { MetaAllLeadParticipantsListHead, MetaAllLeadParticipantsListToolbar, FetchMetaParticipants } from '../../sections/@dashboard/facebook';

//database
import { getAllLeadFormParticipants} from '../../_data/facebook/getLeadFormDatas';
import SessionExpiredDialog from '../../components/session/sessionExpried';
import getSession from '../../utils/getSession'; //Session Management
import clearSession from '../../utils/clearSession';

import config from '../../config';
import { RestorePageOutlined } from '@mui/icons-material';
const { PUBLIC_URL } = config;
const TransactionIcon = `${PUBLIC_URL}/assets/icons/navbar/ic_transaction.svg`;


function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// function applySortFilter(array, comparator, query, campaignsId, leadFormsId, adIds) {
//  //console.log("leadFormsId: ", leadFormsId);
//   if (!Array.isArray(array)) {
//     return [];
//   }
//   const stabilizedThis = array?.map((el, index) => [el, index]);
//   stabilizedThis.sort((a, b) => {
//     const order = comparator(a[0], b[0]);
//     if (order !== 0) return order;
//     return a[1] - b[1];
//   });
//   return stabilizedThis?.map((el) => el[0]).filter((student) => {
//     const lowerCaseQuery = String(query).toLowerCase(); 
//     const matchQuery = query ?
//       Object.values(student).some((value) => String(value).toLowerCase().includes(lowerCaseQuery)) :
//       true;  

//     const matchLeadForm = leadFormsId && leadFormsId !== -1 ? student.lead_form_id === leadFormsId : true;
//     const matchAdIds = adIds && adIds !== -1 ? student.ad_id === adIds : true;
//     const matchCampaignIds = campaignsId && campaignsId !== -1 ? student.campaign_id === campaignsId : true;

//     return matchQuery && matchLeadForm && matchAdIds && matchCampaignIds;
//   });
// };

function applySortFilter(array, comparator, query, campaignsId, adSetIds, leadFormsId, adIds, startDate, endDate, countryName) {
// function applySortFilter(array, comparator, query, campaignsId, adSetIds, leadFormsId, adIds) {
  if (!Array.isArray(array)) {
    return [];
  }

  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  return stabilizedThis.map((el) => el[0]).filter((student) => {
    // Search Query Matching
    const lowerCaseQuery = String(query).toLowerCase(); 
    const matchQuery = query
      ? Object.values(student).some((value) => String(value).toLowerCase().includes(lowerCaseQuery))
      : true;

    // Individual Filter Conditions
    const matchCampaignIds = campaignsId && campaignsId !== -1 ? student.campaign_id === campaignsId : true;
    const matchAdSetIds = adSetIds && adSetIds !== -1 ? student.ad_set_id === adSetIds : true;
    const matchLeadForm = leadFormsId && leadFormsId !== -1 ? student.lead_form_id === leadFormsId : true;
    const matchAdIds = adIds && adIds !== -1 ? student.ad_id === adIds : true;
    const matchCountryName = countryName && countryName !== -1 ? student.country === countryName : true;

    // Date Range Filter
    const rowDate = dayjs(student.participant_join_date);
    const isDateInRange = startDate && endDate 
      ? rowDate.isBetween(dayjs(startDate), dayjs(endDate), null, '[]')
      : true;
    
    // Final Filter Application: Only Consider True Conditions
   // return matchQuery && matchLeadForm && matchAdIds && matchCampaignIds && matchAdSetIds;
    return matchQuery && matchLeadForm && matchAdIds && matchCampaignIds && matchAdSetIds && isDateInRange && matchCountryName;
  });
}

export default function AllMetaParticipantsPage() {
  const { showLoading, hideLoading } = useLoading();
  const { t } = useTranslation();

  // -------------------------COLUMN TITLES-------------------------------------------------- -----

  const TABLE_HEAD = [
    { id: 'campaign_id', label: 'Campaign ID', alignRight: false },
    { id: 'campaign_name', label: 'Campaign Name', alignRight: false },
    { id: 'ad_set_id', label: 'Advert Set ID', alignRight: false },
    { id: 'ad_set_name', label: 'Advert Set Name', alignRight: false },
    { id: 'country', label: 'Country', alignRight: false },
    { id: 'ad_id', label: 'Advert ID', alignRight: false },
    { id: 'advert_name', label: 'Advert Name', alignRight: false },
    { id: 'lead_form_id', label: 'Lead ID', alignRight: false },
    { id: 'lead_name', label: 'Lead Name', alignRight: false },
    { id: 'status', label: 'Status', alignRight: false },
    { id: 'participant_join_id', label: 'Participant Join ID', alignRight: false },
    { id: 'participant_join_date', label: 'Participant Join Date', alignRight: false },
    { id: 'fullname', label: 'Full Name', alignRight: false },
    { id: 'email', label: 'Email', alignRight: false },
    { id: 'whatsapp_number', label: 'WhatsApp', alignRight: false },
    { id: 'locale', label: 'Locale', alignRight: false },    
    // { id: '' },
  ];
  // ---------------------------------------------------------------------------------------------------------  
  const [open, setOpen] = useState(null);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('coursename');
  const [filterStudentId, setfilterStudentId] = useState('');
  const [campaignsName, setCampaignsName] = useState('');
  const [campaignsId, setCampaignsId] = useState('');
  const [adSetsName, setAdSetsName] = useState('');
  const [adSetsId, setAdSetsId] = useState('');  
  const [adName, setAdName] = useState('');
  const [adIds, setAdIds] = useState('');
  const [leadFormsName, setLeadFormsName] = useState('');
  const [leadFormsId, setLeadFormsId] = useState('');
  const [countryName, setCountryName] = useState('');
  const [campaignsCount, setCampaignCount] = useState();
  const [advertSetCount, setAdvertSetCount] = useState();
  const [advertCount, setAdvertCount] = useState();
  const [leadFormCount, setLeadFormCount] = useState();
  const [countryCount, setCountryCount] = useState();
  const [emptyHide, setEmptyHide] = useState();

  const [joinDate, setJoinDate] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [filterNullRecord, setFilterNullRecord] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [leadFormDatas, setLeadFormDatas] = useState([]);
  const [recordsNumber, setRecordsNumber] = useState(0);
  const [openAlertMsg, setOpenAlertMsg] = useState(false); //Notification Show/hide
  const [severity, setSeverity] = useState('success'); //Notification Show/hide  
  const [alertMessage, setAlertMessage] = useState('');
  const [selectData, setSelectData] = useState();
  const [selectedBatch, setSelectedBatch] = useState("");
  const [msgOpen, setMsgOpen] = useState(false);
  const [titleLabel, setTitleLabel] = useState("Oak Batch Names");
  const [nextTransactions, setNextTransactions] = useState([]);
  const [firstStudentRecords, setFirstStudentRecords] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [newTotalTasks, setNewTotalTasks] = useState(0);
  const [isCoordinatorLeader, setIsCoordinatorLeader] = useState(false);
  const [nextPage, setNextPage] = useState(null); // Bir sonraki sayfa URL'si
  const [prevPage, setPrevPage] = useState(null); // Önceki sayfa URL'si
  const navigate = useNavigate();
  const [invalidEmailCount, setInvalidEmailCount] = useState(0);
  const [invalidPhoneNoCount, setInvalidPhoneNoCount] = useState(0);
  
  // Email validation regex pattern
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  // const whatsappRegex = /^\+?[1-9]\d{1,14}$/; //no space
  //const whatsappRegex = /^\+?[1-9]\d{0,3}(\s?\d{1,4}){1,3}$/; //there are spaces    
  const whatsappRegex = /^\+?[1-9]\d{0,3}(\s?\d{1,4})+$/;


  function formatInternationalPhoneNumber(phoneNumber) {
    try {
        // phoneNumber'ın tanımlı olup olmadığını, string olup olmadığını ve uygun formatta olup olmadığını kontrol ediyoruz
        if (!phoneNumber || typeof phoneNumber !== 'string' || !/^(00|\+)?\d+$/.test(phoneNumber.replace(/\s+/g, ''))) {
            return { message: '[Geçersiz numara]', formatted: phoneNumber };
        }
        // + yerine 00 ile başlıyorsa + olarak değiştir
        if (phoneNumber.startsWith('00')) {
            phoneNumber = `+${phoneNumber.slice(2)}`;
        }
        const hasPlus = phoneNumber.startsWith('+');
        // Boşlukları kaldırarak normalizedNumber oluşturuyoruz
        const normalizedNumber = (hasPlus ? phoneNumber.slice(1) : phoneNumber).replace(/\s+/g, '');
        // Başında 0 ile başlayan 11 haneli numaraları veya başında 0, +, veya 00 olmayan 10 haneli numaraları [Ulke Kodu yok] olarak işaretleyin
        if ((normalizedNumber.startsWith('0') && normalizedNumber.length === 11) || (!hasPlus && !normalizedNumber.startsWith('0') && normalizedNumber.length === 10)) {
            const areaCode = normalizedNumber.startsWith('0') ? normalizedNumber.slice(1, 4) : normalizedNumber.slice(0, 3);
            const mainNumber = normalizedNumber.startsWith('0') ? normalizedNumber.slice(4) : normalizedNumber.slice(3);
            const formattedMainNumber = mainNumber.replace(/(\d{3})(\d{2})(\d{2})/, '$1 $2 $3');
            return { message: '[Ulke Kodu yok]', formatted: `${areaCode} ${formattedMainNumber}` };
        }
        // Başında 0 ile başlayan 12 haneli numaraları veya başında 0, +, veya 00 olmayan 10 haneli numaraları [Ulke Kodu yok] olarak işaretleyin
        if ((normalizedNumber.startsWith('0') && normalizedNumber.length === 12) || (!hasPlus && !normalizedNumber.startsWith('0') && normalizedNumber.length === 10)) {
          const areaCode = normalizedNumber.startsWith('0') ? normalizedNumber.slice(1, 4) : normalizedNumber.slice(0, 3);
          const mainNumber = normalizedNumber.startsWith('0') ? normalizedNumber.slice(4) : normalizedNumber.slice(3);
          const formattedMainNumber = mainNumber.replace(/(\d{3})(\d{4})/, '$1 $2');
          return { message: '[Ulke Kodu yok]', formatted: `${areaCode} ${formattedMainNumber}` };
        }
        // Güncellenmiş geçerli format kontrolü
        if (
            (hasPlus && (normalizedNumber.length === 11 || normalizedNumber.length === 12 || normalizedNumber.length === 13 || normalizedNumber.length === 14)) ||  // + ile başlayan ve 11-13 haneli
            (!hasPlus && (normalizedNumber.length === 10 || normalizedNumber.length === 11 || normalizedNumber.length === 13))  // + olmadan 10-13 haneli
        ) {
            let mainNumber, areaCode, countryCode;

            // if (normalizedNumber.length === 14) {
            //   console.log("normalizedNumber-14: ", normalizedNumber);
            //   // 3 haneli ülke kodu ve 11 haneli ana numara
            //   countryCode = normalizedNumber.slice(0, 3);
            //   areaCode = normalizedNumber.slice(3, 6);
            //   mainNumber = normalizedNumber.slice(8);
            // } else 
            if (normalizedNumber.length === 13) {
              //console.log("normalizedNumber-13: ", normalizedNumber);
                // 3 haneli ülke kodu ve 10 haneli ana numara
                countryCode = normalizedNumber.slice(0, 2);
                areaCode = normalizedNumber.slice(2, 5);
                mainNumber = normalizedNumber.slice(7);
            } else if (normalizedNumber.length === 12) {
              //console.log("normalizedNumber-12: ", normalizedNumber);
                // 2 haneli ülke kodu ve 10 haneli ana numara
                countryCode = normalizedNumber.slice(0, 2);
                areaCode = normalizedNumber.slice(2, 5);
                mainNumber = normalizedNumber.slice(5);
            } else if (normalizedNumber.length === 11) {
              //console.log("normalizedNumber-11: ", normalizedNumber);
                // 2 haneli ülke kodu ve 9 haneli ana numara
                countryCode = normalizedNumber.slice(0, 2);
                areaCode = normalizedNumber.slice(2, 5);
                mainNumber = normalizedNumber.slice(5);
            }
            //+49 176 824 27109
            // Ana numarayı uygun biçimde ayrıştırma
            let formattedMainNumber;
            // if (mainNumber.length === 10) {
            //   formattedMainNumber = mainNumber.replace(/(\d{3})(\d{3})(\d{3})/, '$1 $2 $3');
            // } else 
            
            if (mainNumber.length === 9) {
              //console.log("mainNumber-9: ", mainNumber);
                formattedMainNumber = mainNumber.replace(/(\d{3})(\d{3})(\d{3})/, '$1 $2 $3');
            } else if (mainNumber.length === 8) {
              //console.log("mainNumber-8: ", mainNumber);
                formattedMainNumber = mainNumber.replace(/(\d{3})(\d{2})(\d{3})/, '$1 $2 $3');
            } else if (mainNumber.length === 7) {
              //console.log("mainNumber-7: ", mainNumber);
                formattedMainNumber = mainNumber.replace(/(\d{3})(\d{2})(\d{2})/, '$1 $2 $3');
            } else if (mainNumber.length === 6) {
              //console.log("mainNumber-6: ", mainNumber);
                formattedMainNumber = mainNumber.replace(/(\d{3})(\d{3})/, '$1 $2');
            } else {
              //console.log("mainNumber-else: ", mainNumber);
                formattedMainNumber = mainNumber;  // Diğer durumlar için olduğu gibi bırak
            }            
            return {
                message: '',
                formatted: hasPlus
                    ? `+${countryCode} ${areaCode} ${formattedMainNumber}`
                    : `${countryCode} ${areaCode} ${formattedMainNumber}`
            };
        }
        return { message: '[Geçersiz numara]', formatted: phoneNumber };
    } catch (error) {
        console.error("Hata oluştu:", error);
        return { message: '[Geçersiz numara]', formatted: phoneNumber };
    }
  }

  const MENU_OPTIONS = [
    { label0: 'Participants ', },
    { label1: 'Edit ', },
    { label2: 'Delete ', },
  ];

  const [openParticipantsDialog, setOpenParticipantsDialog] = useState(false);
  const [openLecturesDialog, setOpenLecturesDialog] = useState(false);
  const [currentCourseLecturesData, setCurrentCourseLecturesData] = useState(['']);
  const [currentAction, setCurrentAction] = useState();

  const handleAdsParticipantsDialogClose = () => {
    setOpenParticipantsDialog(false);
  };


  const handleDownRecord = async (event, ) => {
    try {     
      const label = event.currentTarget.textContent;
      selected.forEach((item) => {
      });
      if(selected.length > 0){
        switch(label){   
          case t('LFP'):
            //console.log("selected[0]: ", selected[0]);
            //handleEnrollmentDialogOpen(selected[0]); 
            break;       
          default:
            break;
        };
    }else{          
      setSeverity('error');
      setAlertMessage('Not selected Meeting Record!');
      setMsgOpen(true);
    }
    } catch (error) {
        console.log("handleDownRecord-Error: ", error);
    }
  }; 
  
  const handleConfirmDialogClose = () => {
    setOpenConfirmDialog(false);
  };

  const [isSessionExpired, setIsSessionExpired] = useState(false);
  const handleSessionExpiration = () => {
    setIsSessionExpired(true);
  };

  const handleCloseStudentMessage = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setMsgOpen(false);
  };

  const session = getSession();
  const dynamicStructure = session && session.AllRoles ? session.AllRoles : [];

  useEffect(() => {
    getLeadForms();
  }, []);

  const getLeadForms = async () => {
    try {
      showLoading();      
      const response = await getAllLeadFormParticipants();
      const data = Array.isArray(response) ? response : [];
      // Initialize unique value sets
      const campaignSet = new Set();
      const adSetSet = new Set();
      const advertSet = new Set();
      const leadFormSet = new Set();
      const countrySet = new Set();
  
      // // Populate sets with unique values only if `email` is not null or undefined
      data.forEach(row => {
        if (row.email) { // Check if `email` exists and is not null or undefined
          if (row.campaign_id != null) campaignSet.add(row.campaign_id);
          if (row.ad_set_id != null) adSetSet.add(row.ad_set_id);
          if (row.ad_id != null) advertSet.add(row.ad_id);
          if (row.lead_form_id != null) leadFormSet.add(row.lead_form_id);
          if (row.country) countrySet.add(row.country);
        }
      });
  
      // Set state counts for each unique field
      setCampaignCount(campaignSet.size);
      setAdvertSetCount(adSetSet.size);
      setAdvertCount(advertSet.size);
      setLeadFormCount(leadFormSet.size);
      setCountryCount(countrySet.size);
  
      // Additional state settings
      setLeadFormDatas(data);
      setRecordsNumber(data.length);
      setInvalidEmailCount(data.filter(row => !emailRegex.test(row.email)).length);       
      setInvalidPhoneNoCount(data.filter(row => !whatsappRegex.test(row.whatsapp_number)).length);       
    } catch (error) {
      console.error(error);
      setLeadFormDatas([]);
    } finally {
      hideLoading();
    }
  };

  const filterInvalidEmails = (leadFormDatas) => {
    return leadFormDatas.filter(row => emailRegex.test(row.email));
  };

  const filterInvalidPhoneNo = (leadFormDatas) => {
    return leadFormDatas.filter(row => whatsappRegex.test(row.whatsapp_number));
  };

  const [filterRecords, setFilterRecords] = useState();
  const [selectedFullName, setSelectedFullName] = useState('');
  const [selectedUserName, setSelectedUserName] = useState('');
  // const handleOpenMenu = (event, { id, name, status, participant_join_date, full_name, email, whatsapp_number }) => {
  //   setOpen(event.currentTarget);
  //   setSelected([{ id: id, name: name, status: status, participant_join_date: participant_join_date, full_name: full_name, email: email, whatsapp_number: whatsapp_number }]);
  // };

  // const handleCloseMenu = () => {
  //   setOpen(null);
  //   setSelected([]);
  // };

  const handleRequestSort = (event, property) => {   
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  // const handleSelectAllClick = (event) => {
  //   if (event.target.checked) {
  //     if (!Array.isArray(filteredStudents)) {
  //       return;
  //     }
  //     const newSelecteds = filteredStudents?.map((n) => ({
  //       id: n.idstudents,
  //       mail: n.email,
  //       whatsapp_number: n.whatsapp_number,
  //       full_name: `${n.name} ${n.surname}`,
  //     }));
  //     setSelected(newSelecteds);
  //     return;
  //   }
  //   setSelected([]);
  // };

  const handleClick = (event, student) => {
    const selectedIndex = selected.findIndex((item) => item.id === student.id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, student);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }  
    setSelected(newSelected);
  };

  const refreshLeadFormDatas = () => {
    getLeadForms();
  };
  
  const handleConfirm = () => {    
    handleConfirmDialogClose();
  };

  const handleConfirmExtract = () => {    
    handleConfirmExractDialogClose();
  };
  
  const handleConfirmExtractDialogClose = () => {
    setOpenConfirmDialog(false);
  };

  const handleConfirmExractDialogClose = () => {
    setOpenExtractConfirmDialog(false);
  };

  // When Page Changed
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const handleConfirmDialogOpen = (select) => {    
    setCurrentAction(select);
    setOpenConfirmDialog(true);
  };

  const [openConfirmExtractDialog, setOpenExtractConfirmDialog] = useState(false);
  const handleConfirmExtractDialogOpen = (select) => {    
    setCurrentAction(select);
    setOpenExtractConfirmDialog(true);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };


  const handleFilterByName = (event) => {
    setPage(0);
    //console.log("event.target.value: ", event.target.value);
    setfilterStudentId(event.target.value);
  };

  const handleFilterCampaignsByName = (newValue) => {    
    if (!newValue) {
      setPage(0);
      setCampaignsName('');
      setCampaignsId(-1);
      return;
    }
    setPage(0);
    setCampaignsName(newValue.label);
    setCampaignsId(newValue.id);
  };

  const handleFilterLeadFormsByName = (newValue) => {
    //console.log("newValue: ", newValue?.id);
    if (!newValue) {
      setPage(0);
      setLeadFormsName('');
      setLeadFormsId(-1);
      return;
    }
    setPage(0);
    setLeadFormsName(newValue.label);
    setLeadFormsId(newValue.id);
  };

  const handleFilterCountryByName = (newValue) => {
    //console.log("newValue: ", newValue?.id);
    if (!newValue) {
      setPage(0);
      setCountryName(-1);
      return;
    }
    setPage(0);
    setCountryName(newValue.label);
  };

  const handleFilterAdIdsByName = (newValue) => {
    console.log("handleFilterAdIdsByName: ", newValue?.id);
    if (!newValue) {
      setPage(0);
      setAdName('');
      setAdIds(-1);
      return;
    }
    setPage(0);
    setAdName(newValue.label);
    setAdIds(newValue.id);
  };

  const handleFilterAdSetIdsByName = (newValue) => {
    console.log("handleFilterAdSetIdsByName: ", newValue?.id);
    if (!newValue) {
      setPage(0);
      setAdSetsName('');
      setAdSetsId(-1);
      return;
    }
    setPage(0);
    setAdSetsName(newValue.label);
    setAdSetsId(newValue.id);
  };

  //   const handleFilterByNullRecord = (newValue) => {
  //   try {
  //     showLoading();
  //     if (newValue) {      
  //       setPage(0);
  //       setFilterNullRecord(newValue);
  //     } else{      
  //       setPage(0);
  //       setFirstStudentRecords([]); 
  //       setFilterNullRecord(false);
  //       return;
  //     }      
  //   } catch (error) {
  //     console.log("[Error]-handleFilterByFirstRecord: ", error);
  //   }finally{
  //     hideLoading();
  //   }
  // };
  // Komponentin başında geçersiz e-postaları gösterip göstermemek için bir state ekliyoruz.
  const [showInvalidEmails, setShowInvalidEmails] = useState(false);
  const [showInvalidWhatsappNo, setShowInvalidWhatsappNo] = useState(false);

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - leadFormDatas.length) : 0;
  
  // Toplam kayıt sayısı
  const totalRecordCount = leadFormDatas.length;
  const [filteredStudents, setFilteredStudents] = useState([]);
  dayjs.extend(isBetween);


  useEffect(() => {  // Filtreleme işlemi
    const newFilteredStudents = leadFormDatas.filter(row => {
      const ismatchCampaignIds = campaignsId ? row.campaign_id === campaignsId : true;
      const ismatchAdSetIds = adSetsId ? row.ad_set_id === adSetsId : true;
      const ismatchAdIds = adIds ? row.ad_id === adIds : true;
      const ismatchLeadForm = leadFormsId ? row.lead_form_id === leadFormsId : true;
      const lowerCaseQuery = String(filterStudentId).toLowerCase();
      const ismatchSearch = filterStudentId
      ? Object.values(row).some((field) =>
          String(field).toLowerCase().includes(lowerCaseQuery)
        )
      : true;
      // Tarih filtresini kontrol et
      const rowDate = dayjs(row.participant_join_date);
      const isDateInRange = startDate && endDate 
        ? rowDate.isBetween(dayjs(startDate), dayjs(endDate), null, '[]')
        : true;

      // `emptyHide` true ise, `email` alanı boş olanları gizle
      const isEmailNotEmpty = emptyHide ? row.email && row.email.trim() !== "" : true;
      //console.log("isEmailNotEmpty: ", isEmailNotEmpty);
      const result = ismatchCampaignIds && ismatchAdSetIds && ismatchAdIds && ismatchLeadForm && isDateInRange && ismatchSearch && isEmailNotEmpty;
      //console.log(`  Overall Result for Row: ${result}`);
      return result;
      // const result = ismatchCampaignIds && ismatchAdSetIds && ismatchAdIds && ismatchLeadForm && isDateInRange && ismatchSearch;
    });
    //console.log("newFilteredStudents: ", newFilteredStudents);
    setFilteredStudents(newFilteredStudents);  
  }, [filterStudentId, campaignsId, adSetsId, adIds, leadFormsId, leadFormDatas, startDate, endDate, emptyHide]);
  
  //setFilteredLeadFormDatas(leadFormDatas.filter(item => item.email));
  

  const handleFilterStartDate = (newValue) => {
    if (!newValue) {
      setPage(0);
      setStartDate('');
      return;
    }
    setPage(0);
    setStartDate(newValue);
  };

  const handleFilterEndDate = (newValue) => {
    if (!newValue) {
      setPage(0);
      setEndDate('');
      return;
    }
    setPage(0);
    setEndDate(newValue);
  };

  const handleFilterEmptyHide = (newValue) => {
    if (!newValue) {
      setPage(0);
      setEmptyHide(false);
      return;
    }
    setPage(0);
    setEmptyHide(newValue);
  };

  // const [filteredLeadFormDatas, setFilteredLeadFormDatas] = useState([]);
  // useEffect(() => {
  //   if (emptyHide) {
  //     // Eğer emptyHide true ise, email alanı boş olanları filtrele
  //     console.log("email alanı boş olanları filtrele");
  //     setFilteredStudents(leadFormDatas.filter(item => item.email));
  //     //setFilteredLeadFormDatas(leadFormDatas.filter(item => item.email));
  //   } else {
  //     // Eğer emptyHide false ise, tüm verileri göster
  //     console.log("false ise, tüm verileri göster");
  //     setFilteredStudents(leadFormDatas);
  //     //setFilteredLeadFormDatas(leadFormDatas);
  //   }
  // }, [emptyHide, leadFormDatas]);

  // filteredStudents değiştiğinde recordsNumber'ı güncelleme
  useEffect(() => {
    setRecordsNumber(filteredStudents.length);
    // Geçersiz e-posta sayısını güncelleme
    setInvalidEmailCount(filteredStudents.filter(row => !emailRegex.test(row.email)).length);
    // Geçersiz WhatsApp numarası sayısını güncelleme
    setInvalidPhoneNoCount(filteredStudents.filter(row => !whatsappRegex.test(row.whatsapp_number)).length);    
  }, [filteredStudents]);

  useEffect(() => {
    const filtered = applySortFilter(
      leadFormDatas,
      getComparator(order, orderBy),
      filterStudentId,
      campaignsId === '' ? null : campaignsId,
      adSetsId === '' ? null : adSetsId,
      leadFormsId === '' ? null : leadFormsId,
      adIds === '' ? null : adIds,
      startDate,
      endDate,
      countryName
    );
  
    setFilteredStudents(filtered);
    setRecordsNumber(filtered.length);
  }, [filterStudentId, campaignsId, adSetsId, leadFormsId, adIds, leadFormDatas, order, orderBy, startDate, endDate, countryName]);
  
  const isNotFound = Array.isArray(filteredStudents) && filteredStudents.length === 0 && (campaignsId || leadFormsId || adSetsId || adIds || countryName);

  const replaceDateTime = (dateString) => {
    if (!dateString || typeof dateString !== 'string') {
      return '';
    };
    // Cut T ve Z char
    const formattedDate = dateString.replace('T', ' ').replace('Z', '').slice(0, 19);
    return formattedDate;
  };

  // Kartın genel stilini tanımlıyoruz.
  const StyledCard = styled(Card)(({ theme }) => ({
    transition: theme.transitions.create(['transform', 'box-shadow'], {
      duration: theme.transitions.duration.standard,
      easing: theme.transitions.easing.easeInOut,
    }),
    '&:hover': {
      transform: 'translateY(-8px)',
      boxShadow: theme.shadows[10],
    },
    padding: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
  }));

  const StyledCardSummary = ({ title, count, onClick }) => (
    <Card 
      onClick={onClick} 
      sx={{ 
        cursor: 'pointer', 
        height: '100%', 
        boxShadow: 2, 
        '&:hover': { boxShadow: 4 }, 
        borderRadius: 2,
        padding: 2,
        textAlign: 'center',
      }}
    >
      <CardContent>
        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
          <BarChartIcon style={{ fontSize: 40, color: '#6A1B9A' }} />
          <Typography variant="h6" color="primary" sx={{ fontWeight: 'bold', mb: 1 }}>
            {title}
          </Typography>
          <Divider sx={{ width: '50%', mb: 2 }} />
          <Typography variant="h4" color="textPrimary" sx={{ fontWeight: 'bold' }}>
            {count}
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );

  // İkon stilini tanımlıyoruz.
  const StatusIcon = styled(Iconify)(({ theme }) => ({
    fontSize: '3rem',
    color: theme.palette.primary.main,
  }));

  // Kart başlığı stilini tanımlıyoruz.
  const CardTitle = styled(Typography)(({ theme }) => ({
    color: theme.palette.text.secondary,
    fontWeight: theme.typography.fontWeightMedium,
    marginBottom: theme.spacing(1),
  }));

  // Kartta gösterilecek sayı stilini tanımlıyoruz.
  const CardNumber = styled(Typography)(({ theme }) => ({
    color: theme.palette.primary.main,
    fontSize: '2.5rem',
    fontWeight: theme.typography.fontWeightBold,
  }));

  // Kart açıklama stilini tanımlıyoruz.
  const CardDescription = styled(Typography)(({ theme }) => ({
    color: theme.palette.text.disabled,
    fontSize: theme.typography.body2.fontSize,
  }));

  // Dialog bileşeni
  const MetaSummaryDialog = ({ open, onClose, data, title }) => (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>
        {title}
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Name</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row, index) => (
                <TableRow key={index}>
                  <TableCell>{row.id}</TableCell>
                  <TableCell>{row.name}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
    </Dialog>
  );

  const MetaSummaryCard = ({ leadFormDatas }) => {
    const [openDialog, setOpenDialog] = useState(false);
    const [dialogTitle, setDialogTitle] = useState('');
    const [dialogData, setDialogData] = useState([]);
    const [dialogDataCount, setDialogDataCount] = useState([]);

    const handleOpenDialog = (title, id, name, count) => {
        // Benzersiz id'leri ve isimleri almak için Map kullanıyoruz
        const uniqueDataMap = new Map();
        leadFormDatas.forEach(item => {
          //console.log("name: ", name);
            if (item[id]) {
              if(name !== 'country'){
                uniqueDataMap.set(item[id], { id: item[id], name: item[`${id}_name`] || item[name] || 'N/A' });
              }else{
                uniqueDataMap.set(item[id], { name: item[name] || 'N/A' });                
              }
            }
        });

        // Map'ten benzersiz verileri diziye çeviriyoruz
        const uniqueDataArray = Array.from(uniqueDataMap.values());
        //console.log("Country: ", uniqueDataArray);
        setDialogData(uniqueDataArray);
        setDialogTitle(title);
        setDialogDataCount(count);
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const countData = (id) => {
        return [...new Set(leadFormDatas.map(item => item[id]))].filter(Boolean).length;
    };

    return (
        <Box sx={{ flexGrow: 1 }}>
            <Grid container>
                <Grid item xs={12}>
                    <Card sx={{ boxShadow: 3, borderRadius: 2, padding: 3, textAlign: 'center' }}>
                        <CardContent>
                            <Box display="flex" alignItems="center" justifyContent="center">
                                <BarChartIcon style={{ fontSize: 40, color: '#6A1B9A', marginRight: 8 }} />
                                <Typography variant="h5" color="primary" sx={{ fontWeight: 'bold' }}>
                                    Meta Summary
                                </Typography>
                            </Box>
                            <Divider sx={{ my: 2 }} />
                            <Box display="flex" justifyContent="space-around" flexWrap="wrap">
                                <Typography variant="h6" sx={{ fontWeight: 'bold', cursor: 'pointer', mr: 2 }} onClick={() => handleOpenDialog('Campaigns', 'campaign_id', 'campaign_name', countData('campaign_id'))}>
                                    Campaigns: {countData('campaign_id')}
                                </Typography>
                                <Typography variant="h6" sx={{ fontWeight: 'bold', cursor: 'pointer', mr: 2 }} onClick={() => handleOpenDialog('Advert Sets', 'ad_set_id', 'ad_set_name', countData('ad_set_id'))}>
                                    Advert Sets: {countData('ad_set_id')}
                                </Typography>
                                <Typography variant="h6" sx={{ fontWeight: 'bold', cursor: 'pointer', mr: 2 }} onClick={() => handleOpenDialog('Adverts', 'ad_id', 'advert_name', countData('ad_id'))}>
                                    Adverts: {countData('ad_id')}
                                </Typography>
                                <Typography variant="h6" sx={{ fontWeight: 'bold', cursor: 'pointer' }} onClick={() => handleOpenDialog('Lead Forms', 'lead_form_id', 'lead_name', countData('lead_form_id'))}>
                                    Lead Forms: {countData('lead_form_id')}
                                </Typography>
                                
                            </Box>  
                            <Typography variant="h6" sx={{ fontWeight: 'bold', cursor: 'pointer' }} onClick={() => handleOpenDialog('Country', 'country', 'country', countData('country'))}>
                                    Country: {countData('country')}
                                </Typography>
                            <Typography variant="body2" color="textSecondary" mt={3}>
                              The data of participants is presented with grouped numerical values.
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>  
            </Grid>  
            {/* Dialog */}        
            <Dialog open={openDialog} onClose={handleCloseDialog} maxWidth="sm" fullWidth>
                <DialogTitle>
                    {dialogTitle} - {dialogDataCount}
                    <IconButton
                        aria-label="close"
                        onClick={handleCloseDialog}
                        sx={{ position: 'absolute', right: 8, top: 8 }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent dividers>
                    <Table>
                        <TableBody>
                            {dialogData.map((row, index) => (
                                <TableRow key={index}>
                                    <TableCell>{row.id}</TableCell>
                                    <TableCell>{row.name}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </DialogContent>
            </Dialog>
        </Box>
      );
  };

  const [selectedRowId, setSelectedRowId] = useState(null);
  const [selectedLeadCount, setSelectedLeadCount] = useState(null);
  const [selectedLeadName, setSelectedLeadName] = useState(null);
  const handleRowClick = (row) => {
    // console.log('Selected Lead Name:', row.lead_name);
    // console.log('Selected participant_join_id:', row.participant_join_id);
    // console.log('Selected leads_count:', row.leads_count);
    //setSelectedRowId(row.lead_form_id);
    setSelectedRowId(row.participant_join_id);
    setSelectedLeadCount(row.leads_count);
    setSelectedLeadName(row.lead_name);
  };

  const [isChecked, setIsChecked] = useState(false);
  return (
    <>    
      <Helmet>
        <title> {t('Page.Title')} | Oak Academy </title>
      </Helmet>
      <Container maxWidth={false} sx={{ marginLeft: 0, maxWidth: 'none' }}>
        <Grid item xs={12} sm={6} md={3}>
          <Typography variant="h4" gutterBottom>
            <span style={{ fontWeight: 'bold', fontSize: '25px', color: "navy" }}>{t('Lead Form Participants')}</span>            
          </Typography>  
        </Grid>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={3}>  
            <Grid item xs={12} sm={6} md={3}>
              <StyledCard 
                // onClick={() => handleFilterStatusByName(null)} 
                  sx={{ cursor: 'pointer', height: '100%' }} // Ensure full height and pointer on hover
              >
                <CardContent>
                  <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                    <StatusIcon icon={`eva:checkmark-circle-2-fill`} />
                    {/* <BarChartIcon style={{ fontSize: 30, color: 'purple' }} /> */}
                    <Typography variant="h6" color="primary" gutterBottom mb={2}>
                      Meta Participant Records
                    </Typography>
                    <Typography variant="h4" color="textPrimary" mb={2}>
                      {recordsNumber}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" mb={2}>
                      Total Participand Records
                    </Typography>
                    <Typography variant="body2" color="textSecondary" mb={2}>
                      ( Invalid Phone: {invalidPhoneNoCount} )
                    </Typography>
                  </Box>
                </CardContent>
              </StyledCard>            
            </Grid>         
            <Grid item xs={12} sm={6} md={3}>
              <StyledCard 
                // onClick={() => handleFilterStatusByName(null)} 
                  sx={{ cursor: 'pointer', height: '100%' }} // Ensure full height and pointer on hover
              >
                <CardContent>
                  <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                  <StatusIcon icon={`eva:clock-fill`} />                  
                    <Typography variant="h6" color="navy" gutterBottom mb={3}>
                      Meta Lead Form State
                    </Typography>
                    <Typography variant="body2" color="black" align="center" mb={2} >
                      {selectedLeadName}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      Participant Count: <span style={{color: 'red'}}>{selectedLeadCount}</span>
                    </Typography>
                  </Box>
                </CardContent>
              </StyledCard>            
            </Grid>
            <Grid item>
              <MetaSummaryCard leadFormDatas={leadFormDatas} />
            </Grid>
          </Grid>
          <Grid container justifyContent="flex-end">
            <FetchMetaParticipants />
          </Grid>
        </Box>
          {/* <FormControlLabel
            control={
              <Checkbox
                checked={showInvalidWhatsappNo}                
                onChange={(event) => setShowInvalidWhatsappNo(event.target.checked)}
                name="showInvalidWhatsappNo"
                color="primary"
              />
            }
            label="Show invalid Whatsapp No"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={showInvalidEmails}
                onChange={(event) => setShowInvalidEmails(event.target.checked)}
                name="showInvalidEmails"
                color="primary"
              />autocompleteAdSetIdsRef
            }
            label="Show invalid emails"
          /> */}
      </Stack>   
        <Card>
          <MetaAllLeadParticipantsListToolbar 
            numSelected={selected.length} 
            filterName={filterStudentId} 
            onFilterName={handleFilterByName} //Search field
            onCampaignsName={handleFilterCampaignsByName}
            onAdSetsName={handleFilterAdSetIdsByName}
            onAdsName={handleFilterAdIdsByName}
            onLeadFormsName={handleFilterLeadFormsByName}
            onStartDate={handleFilterStartDate}
            onEndDate={handleFilterEndDate}
            onEmptyHide={handleFilterEmptyHide}
            onCountry={handleFilterCountryByName}
          />
           <Divider sx={{ mb: 1 }} />
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800, maxHeight: '70vh', overflow: 'auto' }}>
              <Table stickyHeader>                          
                <MetaAllLeadParticipantsListHead                  
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={recordsNumber}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  // onSelectAllClick={handleSelectAllClick(row)}                 
                />                 
                <TableBody>                                              
                {                  
                    (rowsPerPage === -1 ? filteredStudents : filteredStudents.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage))?.map((row, index) => {
                      const { lead_form_id, lead_name, lead_status, participant_join_date, full_name, email, whatsapp_number, locale, campaign_id, campaign_name, 
                      ad_set_id, ad_set_name, ad_id, advert_name, participant_join_id, country, leads_count } = row;
                      //const selectedStudent = selected.indexOf(lead_form_id) !== -1;
                      const selectedStudent = selectedRowId === participant_join_id; // Seçilen satır kontrolü

                      const isItemSelected = selected.some(item => item.lead_form_id === lead_form_id);
                      const isValidEmail = emailRegex.test(email);
                    return (
                      <TableRow 
                        hover key={index} 
                        tabIndex={-1} 
                        //role="checkbox" 
                        selected={selectedStudent}
                        onClick={() => handleRowClick(row)}
                        sx={{
                          backgroundColor: selectedStudent ? 'rgba(25, 118, 210, 0.2)' : 'inherit', // Seçili satır için renk
                          '&:hover': {
                              backgroundColor: selectedStudent ? 'rgba(25, 118, 210, 0.3)' : 'rgba(0, 0, 0, 0.04)', // Hover rengi
                          },
                        }}
                        >
                        
                        
                        {/* <TableCell component="th" scope="row" padding="none" >
                          <Stack direction="row" alignItems="center" spacing={2}> 
                            <Typography variant="subtitle2" noWrap sx={{ paddingLeft: '3px' }}>
                              {lead_name}
                            </Typography>
                          </Stack>
                        </TableCell>  */}
                        <TableCell align="left">
                          {campaign_id}
                        </TableCell>                                        
                        <TableCell align="left">
                          {campaign_name}
                        </TableCell> 
                        <TableCell align="left">
                          {ad_set_id}
                        </TableCell>                                        
                        <TableCell align="left">
                          {ad_set_name}
                        </TableCell>
                        <TableCell align="left">
                          {country}
                        </TableCell>
                        <TableCell align="left">
                          {ad_id}
                        </TableCell>                                        
                        <TableCell align="left">
                          {advert_name}
                        </TableCell>
                        <TableCell align="left">
                          {lead_form_id}
                        </TableCell>      
                        <TableCell align="left">
                          {lead_name}
                        </TableCell>      
                        <TableCell align="left">
                          <Label
                            sx={{ 
                              backgroundColor: lead_status == "ACTIVE" ? 'defaultBackground' : '#ffcccc',
                              color:
                                lead_status == "ACTIVE" ? 'darkgreen' : 
                                lead_status == "PASIVE" ? 'secondary.main' : 'error'
                            }}>
                              { lead_status }
                          </Label>
                        </TableCell>
                        <TableCell align="left">
                          {participant_join_id}
                        </TableCell> 
                        <TableCell align="left">
                          {replaceDateTime(participant_join_date)}
                        </TableCell>
                        <TableCell align="left">
                          {full_name}
                        </TableCell>
                        <TableCell align="left">
                          <Typography style={{ color: isValidEmail ? 'inherit' : 'red' }}>
                            {email}
                          </Typography>
                        </TableCell>
                        <TableCell align="left">
                          {
                              (() => {
                                  const result = formatInternationalPhoneNumber(whatsapp_number);
                                  const message = result.message;
                                  const formattedNumber = result.formatted;

                                  return (
                                      <Typography>
                                          {message && (
                                              <span style={{ color: 'red', fontWeight: 'normal' }}>
                                                  {message}
                                              </span>
                                          )}
                                          <br/>
                                          <span style={{ color: 'black' }}> {formattedNumber}</span>
                                      </Typography>
                                  );
                              })()
                          }
                        </TableCell>
                        <TableCell align="left">
                          {locale}
                        </TableCell>                       
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
                {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Not found
                          </Typography>

                          <Typography variant="body2">
                            No results found for &nbsp;
                            <strong>&quot;{filterStudentId}&quot;</strong>.
                            <br /> Try checking for typos or using complete words.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}              
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[15, 25, 50, { value: -1, label: 'All' }]}
            component="div"
            count={recordsNumber}            
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>

      <Snackbar 
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }} 
        open={msgOpen}  
        autoHideDuration={5000} 
        onClose={handleCloseStudentMessage}
      >
        <Alert severity={severity} onClose={handleCloseStudentMessage} 
        sx={{ 
          width: 480, minWidth: 350, minheight: 100, height: 130, fontSize: 14, 
          fontStyle: 'normal',
          whiteSpace: 'pre-line'
        }} >
          <Typography style={{ whiteSpace: 'pre-line' }}>
            {alertMessage}
          </Typography>
        </Alert>
      </Snackbar>

      <Dialog
        open={openConfirmDialog}
        onClose={handleConfirmDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Are you sure?"}</DialogTitle>
        <DialogContent>
          {selected?.map((select, index) => (              
            <p key={index} style={{fontWeight: 'bold', color: "#1640D6", marginBottom: 2 }}>
              {selectedFullName }{' /  '}{selectedUserName }{'  '}
            </p>
          ))}
          <p>
            The registration of the student(s) whose above information is visible will be deleted.
            <br/>
            Are you sure you want to do this?
            
          </p>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirmDialogClose} color="primary">
          {t('Cancel')}
          </Button>
          <Button 
            onClick={handleConfirm} 
            autoFocus
            style={{
              backgroundColor: 'red',
              color: '#FFFFFF',
            }}
          >
            {t('Update')}
          </Button>
        </DialogActions>        
      </Dialog>        
      <Dialog
        open={openConfirmExtractDialog}
        onClose={handleConfirmExtractDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Are you sure?"}</DialogTitle>
        <DialogContent>
          {selected?.map((select, index) => (              
            <p key={index} style={{fontWeight: 'bold', color: "#1640D6", marginBottom: 2 }}>
              {selectedFullName }{' /  '}{selectedUserName }{'  '}
            </p>
          ))}
          <p>
            The interest and registration of the student(s) whose information is shown above will be removed from all platforms.
          </p>
              <br/>
              Are you sure you want to do this?          
        </DialogContent>
        <DialogActions>
          <Button sx = {{ pr: 10 }} onClick={handleConfirmExractDialogClose} color="primary">
          {t('Cancel')}
          </Button>
          <Button 
            sx = {{ pr: 10, textAlign: 'center' }}
            onClick={handleConfirmExtract} 
            autoFocus
            style={{
              backgroundColor: 'red',
              color: '#FFFFFF',
            }}
          >
            {t('Update')}
          </Button>
        </DialogActions>        
      </Dialog>

      <Dialog
        open={openParticipantsDialog}
        onClose={handleAdsParticipantsDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          sx: { width: "80%", maxWidth: "none" } 
        }}
      >
        <DialogTitle id="alert-dialog-title">
          {Array.isArray(currentAction) && currentAction.length > 0 && selected[0] && (
            <>
              <Typography variant="body4" component="span" style={{ color: 'red' }}>
                {selected[0].name}
              </Typography>
              <br/> 
              <Typography variant="body2" component="span" style={{ color: 'text' }}>
                Number of Participant : {currentAction.length}
              </Typography>
              
            </>
          )}
        </DialogTitle>
        <DialogActions>
          <Button
            sx = {{ pl: 2, pr: 2, textAlign: 'center' }}
            onClick={handleAdsParticipantsDialogClose}
            autoFocus
            style={{
              backgroundColor: '#6C757D',
              color: '#FFFFFF',
            }}
          >
            CLOSE
          </Button>          
        </DialogActions>        
      </Dialog>
    </>
  );
};