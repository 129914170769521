import PropTypes from 'prop-types';
// @mui
import { Box, Checkbox, TableRow, TableCell, TableHead, TableSortLabel, Tooltip } from '@mui/material';
import { styled } from '@mui/system';

// Görsel olarak gizlemek için kullanılıyor
const visuallyHidden = {
  border: 0,
  margin: -1,
  padding: 0,
  width: '1px',
  height: '1px',
  overflow: 'hidden',
  position: 'absolute',
  whiteSpace: 'nowrap',
  clip: 'rect(0 0 0 0)',
};

// Başlık hücresini stillendiriyoruz
const StyledTableCell = styled(TableCell)(({ theme, highlight }) => ({
  backgroundColor: '#fff', // Arka plan beyaz, böylece tablo kayarken yazılar üstte kalır
  position: 'sticky',
  top: 0,
  zIndex: theme.zIndex.appBar, // Başlık satırının üstte kalması için yüksek z-index
  borderRight: `1px solid ${theme.palette.divider}`,
  fontWeight: highlight === 'true' ? 'bold' : 'inherit',
  fontSize: highlight === 'true' ? '18px' : 'inherit',
  // İsteğe bağlı renklendirme:
  // color: highlight === 'true' ? 'green' : 'inherit',
}));

StudentListHead.propTypes = {
  order: PropTypes.oneOf(['asc', 'desc']),
  orderBy: PropTypes.string,
  rowCount: PropTypes.number,
  headLabel: PropTypes.array,
  numSelected: PropTypes.number,
  onRequestSort: PropTypes.func,
  onSelectAllClick: PropTypes.func,
};

export default function StudentListHead({
  order,
  orderBy,
  rowCount,
  headLabel,
  numSelected,
  onRequestSort,
  onSelectAllClick,
}) {
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <StyledTableCell padding="checkbox" highlight="false">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
          />
        </StyledTableCell>
        {headLabel.map((headCell) => (
          <Tooltip
            key={headCell.id}
            title={headCell.id === 'futurecontactdate' ? 'Records with a contact date of today are highlighted.' : ''}
          >
            <StyledTableCell
              align={headCell.alignRight ? 'right' : 'center'}
              sortDirection={orderBy === headCell.id ? order : false}
              highlight={headCell.id === 'futurecontactdate' ? 'true' : 'false'}
            >
              <TableSortLabel
                hideSortIcon
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box sx={{ ...visuallyHidden }}>{order === 'desc' ? 'sorted descending' : 'sorted ascending'}</Box>
                ) : null}
              </TableSortLabel>
            </StyledTableCell>
          </Tooltip>
        ))}
      </TableRow>
    </TableHead>
  );
}



// import PropTypes from 'prop-types';
// // @mui
// import { Box, Checkbox, TableRow, TableCell, TableHead, TableSortLabel, Tooltip } from '@mui/material';
// import { styled } from '@mui/system';

// // ----------------------------------------------------------------------

// const visuallyHidden = {
//   border: 0,
//   margin: -1,
//   padding: 0,
//   width: '1px',
//   height: '1px',
//   overflow: 'hidden',
//   position: 'absolute',
//   whiteSpace: 'nowrap',
//   clip: 'rect(0 0 0 0)',
// };

// const StyledTableCell = styled(TableCell)(({ theme, highlight }) => ({
//   backgroundColor: highlight === 'true' ? theme.palette.warning.light : 'inherit',
//   // color: highlight ? theme.palette.primary.contrastText : 'inherit',
//   color: highlight === 'true' ? 'green' : 'inherit',
//   fontWeight: highlight === 'true' ? 'bold' : 'inherit',
//   fontSize: highlight === 'true' ? '18px' : 'inherit',
// }));


// StudentListHead.propTypes = {
//   order: PropTypes.oneOf(['asc', 'desc']),
//   orderBy: PropTypes.string,
//   rowCount: PropTypes.number,
//   headLabel: PropTypes.array,
//   numSelected: PropTypes.number,
//   onRequestSort: PropTypes.func,
//   onSelectAllClick: PropTypes.func,
// };


// // export default function StudentListHead({
// //   order,
// //   orderBy,
// //   rowCount,
// //   headLabel,
// //   numSelected,
// //   onRequestSort,
// //   onSelectAllClick,
// // }) {
// //   const createSortHandler = (property) => (event) => {
// //     onRequestSort(event, property);
// //   };

// //   return (
// //     <TableHead>
// //       <TableRow>
// //         <TableCell padding="checkbox" sx={{ borderRight: 1, borderColor: 'divider' }}>
// //           <Checkbox
// //             indeterminate={numSelected > 0 && numSelected < rowCount}
// //             checked={rowCount > 0 && numSelected === rowCount}
// //             onChange={onSelectAllClick}
// //           />
// //         </TableCell>
// //         {headLabel.map((headCell) => (
// //           <StyledTableCell
// //             key={headCell.id}
// //             align={headCell.alignRight ? 'right' : 'center'}
// //             sortDirection={orderBy === headCell.id ? order : false}
// //             sx={{ borderRight: 1, borderColor: 'divider' }}
// //             highlight={headCell.id === 'futurecontactdate' ? 'true' : 'false'}
// //           >
// //             {headCell.id === 'futurecontactdate' ? (
// //               <Tooltip title="Future Contact Date Column">
// //                 <TableSortLabel
// //                   hideSortIcon
// //                   active={orderBy === headCell.id}
// //                   direction={orderBy === headCell.id ? order : 'asc'}
// //                   onClick={createSortHandler(headCell.id)}
// //                 >
// //                   {headCell.label}
// //                   {orderBy === headCell.id ? (
// //                     <Box sx={{ ...visuallyHidden }}>{order === 'desc' ? 'sorted descending' : 'sorted ascending'}</Box>
// //                   ) : null}
// //                 </TableSortLabel>
// //               </Tooltip>
// //             ) : (
// //               <TableSortLabel
// //                 hideSortIcon
// //                 active={orderBy === headCell.id}
// //                 direction={orderBy === headCell.id ? order : 'asc'}
// //                 onClick={createSortHandler(headCell.id)}
// //               >
// //                 {headCell.label}
// //                 {orderBy === headCell.id ? (
// //                   <Box sx={{ ...visuallyHidden }}>{order === 'desc' ? 'sorted descending' : 'sorted ascending'}</Box>
// //                 ) : null}
// //               </TableSortLabel>
// //             )}
// //           </StyledTableCell>
// //         ))}
// //       </TableRow>
// //     </TableHead>
// //   );
// // }

// // // TableRow rendering part
// // export function StudentListRow({ row }) {
// //   return (
// //     <TableRow hover role="checkbox" tabIndex={-1}>
// //       {row.map((cell) => (
// //         <StyledTableCell key={cell.id}>
// //           {cell.id === 'futurecontactdate' ? (
// //             <Tooltip title="This is the future contact date">
// //               <span style={{ color: cell.highlight === 'true' ? 'red' : 'inherit' }}>{cell.value}</span>
// //             </Tooltip>
// //           ) : (
// //             cell.value
// //           )}
// //         </StyledTableCell>
// //       ))}
// //     </TableRow>
// //   );
// // }

// // StudentListRow.propTypes = {
// //   row: PropTypes.array.isRequired,
// // };







// // export default function StudentListHead({
// //   order,
// //   orderBy,
// //   rowCount,
// //   headLabel,
// //   numSelected,
// //   onRequestSort,
// //   onSelectAllClick,
// // }) {
// //   const createSortHandler = (property) => (event) => {
// //     onRequestSort(event, property);
// //   };

// //   return (
// //     <TableHead>
// //       <TableRow>
// //         <TableCell padding="checkbox" sx={{ borderRight: 1, borderColor: 'divider' }}>
// //           <Checkbox
// //             indeterminate={numSelected > 0 && numSelected < rowCount}
// //             checked={rowCount > 0 && numSelected === rowCount}
// //             onChange={onSelectAllClick}
// //           />
// //         </TableCell>
// //         {headLabel.map((headCell) => (
// //           <Tooltip key={headCell.id} title={headCell.id === 'futurecontactdate' ? 'Future Contact Date Column' : ''}>
// //             <StyledTableCell
// //               align={headCell.alignRight ? 'right' : 'center'}
// //               sortDirection={orderBy === headCell.id ? order : false}
// //               sx={{ borderRight: 1, borderColor: 'divider' }}
// //               highlight={headCell.id === 'futurecontactdate' ? 'true' : 'false'}
// //             >
// //               {headCell.id === 'futurecontactdate' ? (
// //                 <Tooltip title="Special Style Column">
// //                   <TableSortLabel
// //                     hideSortIcon
// //                     active={orderBy === headCell.id}
// //                     direction={orderBy === headCell.id ? order : 'asc'}
// //                     onClick={createSortHandler(headCell.id)}
// //                   >
// //                     {headCell.label}
// //                     {orderBy === headCell.id ? (
// //                       <Box sx={{ ...visuallyHidden }}>{order === 'desc' ? 'sorted descending' : 'sorted ascending'}</Box>
// //                     ) : null}
// //                   </TableSortLabel>
// //                 </Tooltip>
// //               ) : (
// //                 <TableSortLabel
// //                   hideSortIcon
// //                   active={orderBy === headCell.id}
// //                   direction={orderBy === headCell.id ? order : 'asc'}
// //                   onClick={createSortHandler(headCell.id)}
// //                 >
// //                   {headCell.label}
// //                   {orderBy === headCell.id ? (
// //                     <Box sx={{ ...visuallyHidden }}>{order === 'desc' ? 'sorted descending' : 'sorted ascending'}</Box>
// //                   ) : null}
// //                 </TableSortLabel>
// //               )}
// //             </StyledTableCell>
// //           </Tooltip>
// //         ))}
// //       </TableRow>
// //     </TableHead>
// //   );
// // }

// export default function StudentListHead({
//   order,
//   orderBy,
//   rowCount,
//   headLabel,
//   numSelected,
//   onRequestSort,
//   onSelectAllClick,
// }) {
//   const createSortHandler = (property) => (event) => {
//     onRequestSort(event, property);
//   };

//   return (
//     <TableHead>
//       <TableRow>
//         <TableCell padding="checkbox" sx={{ borderRight: 1, borderColor: 'divider' }}>
//           <Checkbox
//             indeterminate={numSelected > 0 && numSelected < rowCount}
//             checked={rowCount > 0 && numSelected === rowCount}
//             onChange={onSelectAllClick}
//           />
//         </TableCell>
//         {headLabel.map((headCell) => (
//           // <TableCell
//           //   key={headCell.id}
//           //   align={headCell.alignRight ? 'right' : 'center'}
//           //   sortDirection={orderBy === headCell.id ? order : false}
//           //   sx={{ borderRight: 1, borderColor: 'divider' }}
//           // >
//           <Tooltip title={headCell.id === 'futurecontactdate' ? 'Records with a contact date of today are displayed in red.' : ''} key={headCell.id}>
//           <StyledTableCell
//             key={headCell.id}
//             align={headCell.alignRight ? 'right' : 'center'}
//             sortDirection={orderBy === headCell.id ? order : false}
//             sx={{ borderRight: 1, borderColor: 'divider' }}
//             highlight={headCell.id === 'futurecontactdate' ? 'true' : 'false'}            
//           >
//             {/* <Tooltip title={headCell.id === 'futurecontactdate' ? 'Future Contact Date Column' : ''}> */}
//             <TableSortLabel
//               hideSortIcon
//               active={orderBy === headCell.id}
//               direction={orderBy === headCell.id ? order : 'asc'}
//               onClick={createSortHandler(headCell.id)}
//             >
//               {headCell.label}
//               {orderBy === headCell.id ? (
//                 <Box sx={{ ...visuallyHidden }}>{order === 'desc' ? 'sorted descending' : 'sorted ascending'}</Box>
//               ) : null}
//             </TableSortLabel>
//             {/* </Tooltip> */}
//         </StyledTableCell>
//         </Tooltip>
//           //  </TableCell>
//         ))}
//       </TableRow>
//     </TableHead>
//   );
// }
