import axios from 'axios';
import config from '../../config';
import getToken from '../api/getToken';
const { BaseUrl } = config;

const getToDateFirstContacts = async (contactDate) => {
    try {      
      const token = await getToken();
      const response = await axios.get(`${BaseUrl}/students/todatefirstcontact`, {
        headers: {
            'Authorization': `Bearer ${token}`
        },
        params: {
          contactDate: contactDate,
        },
      });
      const firstContactRecords = response.data || [];
      return firstContactRecords;
    } catch (error) {
      console.error('Error fetching First Contact Records: ', error);
      return [];
    }
};
export default getToDateFirstContacts;