import axios from 'axios';
import config from '../../config';
import getToken from '../api/getToken';
const { BaseUrl } = config;

const createNewWebStudent = async (email, candidate_name, phone, references) => {
  try {
    console.log("createNewWebStudent: ", email, candidate_name, phone, references);
    const parts = candidate_name.trim().split(/\s+/);
    const surname = parts.pop();
    const name = parts.join(' ');
    const contact = { mail: {mailaddress1: `${email}`, mailaddress2: ""}, phone: {whatsapp: `${phone}`, cellphone1: "", cellphone2: "",}, address: "", city: "", country: "" };
    const description = "Web sitesi Contact Formunu dolduran adaylarin bilgileri, sistem tarafindan otomatik olarak listeye eklenmistir.";
    const memberships = {  Teachable: false, Quizizz: false,  TryHackMe: false, DigitalCampus: false, DiscordUserName: false};
    const status = 4; //candidate student
    const token = await getToken();
    const response = await axios.post(`${BaseUrl}/student/createstudent`,  
      { name: name, surname: surname, email: email, status: status, contact: contact, allreferences: references, description: description, memberships: memberships},
      {
        headers: {
          'Authorization': `Bearer ${token}`
        },
      }
    );
    // console.log("response: ", response);
    // if(response.status === 200){
    //   if(response.data.message === "There is a student record with the same email address!"){
    //     return response.data.message;
    //   };
    // }
    const info = response || [];
    return info;
  } catch (error) {
      return [];
  }
};

export { createNewWebStudent };

