import React, { useEffect, useState, useCallback } from 'react';
import LeaveStatus from './LeaveStatus';
import LeaveRequestForm from './LeaveRequestForm';
import { Grid } from '@mui/material';
import { getEmployeeLeavesById } from '../../_data/hr/getEmployeeLeavesById';
import { createEmployeeLeaves } from '../../_data/hr/createEmployeeLeaves';
import MessageBox from '../../utils/contexts/MessageBox';

const LeaveDashboard = ({ userInfo }) => {
  const [msgOpen, setMsgOpen] = useState(false);
  const [severity, setSeverity] = useState('success');
  const [alertMessage, setAlertMessage] = useState('');
  const [leavesData, setLeavesData] = useState([]);
  const showMessage = (message, severityType = 'success') => {
    setAlertMessage(message);
    setSeverity(severityType);
    setMsgOpen(true);
  };
  const handleCloseMessage = () => {
    setMsgOpen(false);
  };  

  const loadLeavesDatas = useCallback( async ()=>{
    try {
      const data = await getEmployeeLeavesById(userInfo.iduser);
      setLeavesData(data || []);
    } catch (error) {
      console.error("Error fetching leaves data: ", error);
      setLeavesData([]);
    }
  }, [userInfo.iduser]);  
  
  useEffect(() => {    
    loadLeavesDatas();
  }, [loadLeavesDatas]);
  
  const handleLeaveRequestSubmit = async (newRequest, errorMessage) => {
    try {
      if(errorMessage !== null && (errorMessage.Message || errorMessage.Status)){        
        showMessage(errorMessage.Message, errorMessage.Status);
        return;
      }
      const result = await createEmployeeLeaves(userInfo.iduser, newRequest.type, newRequest.startDate, newRequest.endDate, newRequest.reason);
      if(result.data.data.affectedRows === 1){
        showMessage('The record has been added successfully.', 'success');
      }
      loadLeavesDatas();
    } catch (error) {
      console.log("handleLeaveRequestSubmit-Error: ", error);
    }
  };

  return (    
    <>
    <Grid container spacing={3} alignItems="flex-start" sx={{ height: '100%' }}>        
        <Grid item xs={12} md={8}>          
          <LeaveRequestForm onSubmit={handleLeaveRequestSubmit} />
        </Grid>
        <Grid item xs={12} md={4}>
            <LeaveStatus leaveRequests={leavesData} />  
        </Grid>
      </Grid>
      <MessageBox
        msgOpen={msgOpen}
        severity={severity}
        alertMessage={alertMessage}
        handleCloseMessage={handleCloseMessage}
      />
    </>
  );
};

export default LeaveDashboard;