import axios from 'axios';
import getToken from '../api/getToken';
import config from '../../config';
const { BaseUrl } = config;

const getContactDatas= async () => {
  try {
    const token = await getToken(); 
    const response = await axios.get(`${BaseUrl}/contact/getwebformcontact`,{
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    const info = response.data.result || [];
    const ContactDatas = info.map((item) => {
      return { 
        id: item.idstudent_contact_form || '',
        email: item.email || '',
        candidate_name: item.candidate_name || '',
        phone: item.phone || '',
        message: item.message || '',
        link: item.link || '',
        atcreated: item.atcreated || '',
        status: item.status || 0,
        reject_description: item.reject_description || '',
    };
    });
    return ContactDatas;
  } catch (error) {
        console.log("getContactChannals Error: ", error);
  }
};

export { getContactDatas };
