import React, { useState } from "react";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import { fetchAndUpdateLeadFormsByPageId, fetchandupdateleadparticipants,
         fetchAndUpdateFirstRecordOfCordinators } from "../../../_data/facebook/getLeadFormDatas";

const FetchMetaParticipants = () => {
  const [loading, setLoading] = useState(false);
  const [loadingText, setLoadingText] = useState(""); // Dynamic explanation Text
  const [data, setData] = useState(null); // Gelen veriyi tutacak state

  const fetchMetaParticipants = async () => {
    if (loading) {
      alert("Please wait, the data is already being fetched.");
      return;
    }
    setLoading(true); // When start process loading state set true.
    // First description text
    setLoadingText("Data fetching has started for Lead Forms...");
    try {
      const response_PageId = await fetchAndUpdateLeadFormsByPageId();
      setLoadingText("Lead Forms data fetched successfully!");
      await new Promise((resolve) => setTimeout(resolve, 2000));

      setLoadingText("Data fetching has started for Participant...");
      const response_participants = await fetchandupdateleadparticipants();
      setLoadingText("Participants data fetched successfully!");
      await new Promise((resolve) => setTimeout(resolve, 2000));

      setLoadingText("Data fetching and updating has started for Record Of Cordinators...");
      const response_coordinators = await fetchAndUpdateFirstRecordOfCordinators();
      setLoadingText("Record Of Cordinators data fetched & updated successfully!");
      await new Promise((resolve) => setTimeout(resolve, 3000));

      // Set message comming from Backend.
      setTimeout(() => {
      setLoadingText(""); // Clear Message
      setLoading(false); // Close loading case
    }, 2000); // Show success message 2 seccond
    } catch (error) {
      console.error("Error:", error);
      setLoadingText("An error occurred. Please try again.");
      setTimeout(() => setLoading(false), 2000); // In case of error, the loading status is also closed.
    } 
    // finally {
    //   setLoading(false); // When the process is complete, set the loading status to false.
    // }
  };

  return (
    <div style={{ textAlign: "center", marginTop: "20px" }}>
      <Button
        sx={{
          height: 50,
          fontSize: 17,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: loading ? "#FF7043" : "#1a73e8", // Orange during operation.          
          color: loading ? "black" : "white",
          opacity: 1, // Opacity
          cursor: loading ? "not-allowed" : "pointer",
          pointerEvents: loading ? "none" : "auto", // Block clicks.
          "&:hover": {
            backgroundColor: loading ? "#578E7E" : "#FFEB00", // Hover colors #1558b0 #FF5722
            color: loading ? "white" : "black",
          },
        }}
        variant="contained"
        onClick={!loading ? fetchMetaParticipants : undefined} // Clicking is disabled during operation.
      >
        {loading ? (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <CircularProgress size={24} sx={{ color: "#1E90FF", marginRight: "10px" }} />
            {loadingText || "Loading..."}
          </Box>
        ) : (
          "Fetch All Meta Datas"
        )}
        
      </Button>
    </div>
  );
};

export default FetchMetaParticipants;
