import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
// @mui
import { styled, alpha } from '@mui/material/styles';
import { 
  Toolbar, Tooltip, IconButton, Typography, OutlinedInput, InputAdornment, SvgIcon,
  Grid, Autocomplete, Button, Box, TextField, FormControlLabel, Checkbox, Stack, Popper
} from '@mui/material';
import { ReactComponent as Icon_Attendance } from '../../../assets/icons/attendance.svg';
import Iconify from '../../../components/iconify';

//Multi Language
import { useTranslation } from 'react-i18next'; 
import getStatus from '../../../_data/student/getStatus';
import { getBatchNames } from '../../../_data/batchs/getBatchNames';
import getAllCourseCoordinators from '../../../_data/user/getAllCourseCoordination';
import getAllReferences from '../../../_data/student/getAllReferences';
import { getAssessmentLevels } from '../../../_data/assesmentlevels/getAssessmentLevels';

// MUI Date Picker
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

const StyledRoot = styled(Toolbar)(({ theme }) => ({
  minHeight: 96,
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(1, 1, 1, 3),
}));

const StyledSearch = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  transition: theme.transitions.create(['box-shadow','width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&.Mui-focused': {
    width: 320,
    boxShadow: theme.customShadows.z8,
  },
  '& fieldset': {
    borderWidth:`1px !important`,
    borderColor:`${alpha(theme.palette.grey[500],0.32)} !important`,
  },
}));

StudentListToolbar.propTypes = {
  numSelected: PropTypes.number,
  filterName: PropTypes.string,
  onFilterName: PropTypes.func,
  onStatusName: PropTypes.func,
  onBatchName: PropTypes.func,
  onCoordinatorName: PropTypes.func,
  onCallInDays: PropTypes.func,
  onFirstRecord: PropTypes.func,
  onFirstContact: PropTypes.func
};

export default function StudentListToolbar({ 
  numSelected, 
  filterName, 
  onFilterName, 
  onStatusName, 
  onBatchName, 
  onCoordinatorName, 
  onCallInDays, 
  onFirstRecord, 
  onFirstContact,
  onReferenceName,
  onAssessmentLevel
}) {
  const { t } = useTranslation();
  const autocompleteStatusRef = useRef(null);
  const autocompleteBatchRef = useRef(null);
  const autocompleteCoordinatorRef = useRef(null);
  const autocompleteReferenceRef = useRef(null);
  const autocompleteEssessmentLevelRef = useRef(null);

  const [allstatuses, setAllStatuses] = useState([]); 
  const [selectData, setSelectData] = useState([]);
  const [allcoordinators, setAllCoordinators] = useState([]);
  const [allReferences, setAllReferences] = useState([]);
  const [assesmentLevel, setAssesmentLevel] = useState([]);
  const [checked, setChecked] = useState(false);
  const [firstRecordChecked, setFirstRecordChecked] = useState(false);
  const [startDate, setStartDate] = useState(null); 

  useEffect(() => {
    loadStatuses();
    loadBatchNames();
    loadCoordinators();
    loadReferences();
    loadAssessmentLevel();
  }, []);

  const loadStatuses = async () => {
    try {
       const response = await getStatus();
       setAllStatuses(response);
    } catch (error) {
        console.error('Error loading statuses:', error);
    }
  };

  const loadBatchNames = async () => {
    const data = await getBatchNames();    
    if (!data) {
      console.error("No batch data.");
      return;
    }
    setSelectData(data);
  };

  const loadCoordinators = async () => {
    try {
      const response = await getAllCourseCoordinators();
      setAllCoordinators(response);
    } catch (error) {
        console.error('Error loading coordinators:', error);
    }
  };

  const loadReferences = async () => {
    try {
      const response = await getAllReferences();
      setAllReferences(response);
    } catch (error) {
        console.error('Error loading coordinators:', error);
    }
  };

  const loadAssessmentLevel = async () => {
    try {
      const response = await getAssessmentLevels();
      setAssesmentLevel(response);
    } catch (error) {
        console.error('Error loading assessment level:', error);
    }
  };

  const handleBatchChange = (event, newValue) => {
    if (newValue) {
      onBatchName(newValue);
    } else {
      onBatchName(null);
    }
  };

  const handleCheck = (event) => {
    if(event.target.checked){
      setFirstRecordChecked(false);
      onFirstRecord(false);
    }
    setChecked(event.target.checked);
    onCallInDays(event.target.checked);
  };

  const handleFirstRecordCheck = (event) => {
    if(event.target.checked){
      setChecked(false);
      onCallInDays(false);
    }
    setFirstRecordChecked(event.target.checked);
    onFirstRecord(event.target.checked);
  };

  const handleFirstContact = (newValue) => {
    setStartDate(newValue);
    onFirstContact(newValue);
  };

  // Popper ile zIndex'i yükseltmek için özel bir popper component
  const CustomPopper = (props) => (
    <Popper {...props} style={{ zIndex: 9999 }} />
  );

  const handleClearFilters = () => {
    onFilterName({ target: { value: '' } });
    onStatusName(null);
    onBatchName(null);
    onCoordinatorName(null);
    onCallInDays(false);
    onFirstRecord(false);
    onFirstContact(null);
    onReferenceName(''); // Referansı da sıfırla  
    setStartDate(null);
    setChecked(false);
    setFirstRecordChecked(false);
    onAssessmentLevel('')
  };

  return (
    <StyledRoot
      sx={{
        ...(numSelected > 0 && {
          color:'primary.main',
          bgcolor:'primary.lighter',
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography component="div" variant="subtitle1">
          {numSelected} {t('CheckBoxSelected')}
        </Typography>
      ) : (
        <Stack direction="column" spacing={1} sx={{ width:'100%' }} mb={1}>
          {/* ÜST SATIR */}
          <Grid container spacing={1}>
            <Grid item xs={3}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="First Contact Date"
                  value={startDate}
                  onChange={handleFirstContact}
                  slotProps={{
                    popper: { sx: { zIndex: 9999 } }
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={3}>          
              <Autocomplete
                disablePortal={false}
                PopperComponent={CustomPopper}
                id="combo-box-demo"
                options={allReferences ? allReferences.map((reference, index) => ({
                  id: reference.idstudent_references,
                  label: reference.referencename,
                  key: `reference-${reference.idstudent_references || index}` 
                })) : []}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                sx={{ flexGrow:1 }}
                renderInput={(params) => <TextField {...params} label="Search References" inputRef={autocompleteReferenceRef} />}
                onChange={(event, newValue) => {
                  onReferenceName(newValue);
                }}
                ListboxProps={{ style: { maxHeight:'200px', overflow:'auto' } }}
                renderOption={(props, option) => (
                  <Box component="li" sx={{ fontWeight:'normal', color:'#E90074' }} {...props}>
                    {option.label}
                  </Box>
                )}
              />
            </Grid>
            <Grid item xs={3}>          
              <Autocomplete
                disablePortal={false}
                PopperComponent={CustomPopper}
                id="combo-box-demo"
                options={assesmentLevel ? assesmentLevel.map(level => ({
                  id: level.idassesmentlevel,
                  label: level.assesmentlevel
                })) : []}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                sx={{ flexGrow:1 }}
                renderInput={(params) => <TextField {...params} label="Search Assesment Level" inputRef={autocompleteEssessmentLevelRef} />}
                onChange={(event, newValue) => {
                  onAssessmentLevel(newValue);
                }}
                ListboxProps={{ style: { maxHeight:'200px', overflow:'auto' } }}
                renderOption={(props, option) => (
                  <Box component="li" sx={{ fontWeight:'normal', color:'#E90074' }} {...props}>
                    {option.label}
                  </Box>
                )}
              />
            </Grid>
            <Grid item xs={1}>
              <FormControlLabel
                control={<Checkbox name="firstrecord" 
                  checked={firstRecordChecked}
                  onChange={handleFirstRecordCheck} />}
                label="First Record"
              />
            </Grid>
            <Grid item xs={2}>
              <FormControlLabel
                control={<Checkbox name="callIn15Days" 
                  checked={checked}
                  onChange={handleCheck} />}
                label="Call in 15 Days"
              />
            </Grid>
          </Grid>

          {/* ALT SATIR */}
          <Grid container spacing={1}>
            <Grid item xs={3}>
              <StyledSearch
                value={filterName}
                onChange={onFilterName}
                placeholder={t('SearchStudent')}
                startAdornment={
                  <InputAdornment position="start">
                    <Iconify icon="eva:search-fill" sx={{ color:'text.disabled', width:20, height:20 }} />
                  </InputAdornment>
                }
              />              
            </Grid>           
            <Grid item xs={3}>          
              <Autocomplete
                disablePortal={false}
                PopperComponent={CustomPopper}
                id="combo-box-demo"
                options={allcoordinators ? allcoordinators.map(coordinator => ({
                  id: coordinator.id,
                  label: coordinator.name_surname
                })) : []}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                sx={{ flexGrow:1 }}
                renderInput={(params) => <TextField {...params} label="Search Coordinator" inputRef={autocompleteCoordinatorRef} />}
                onChange={(event, newValue) => {
                  onCoordinatorName(newValue);
                }}
                ListboxProps={{ style: { maxHeight:'200px', overflow:'auto' } }}
                renderOption={(props, option) => (
                  <Box component="li" sx={{ fontWeight:'normal', color:'#E90074' }} {...props}>
                    {option.label}
                  </Box>
                )}
              />
            </Grid>

            <Grid item xs={3}>
              <Autocomplete
                disablePortal={false}
                PopperComponent={CustomPopper}
                id="combo-box-batch"
                options={(selectData && Array.isArray(selectData))  ? selectData.map(batch => ({
                  id: batch.batchname,
                  label: batch.batchname
                })) : []}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                sx={{ flexGrow:1 }}
                renderInput={(params) => <TextField {...params} label={t('SearchCourseName')} inputRef={autocompleteBatchRef} />}
                onChange={handleBatchChange}
                ListboxProps={{ style: { maxHeight:'200px', overflow:'auto' } }}
                renderOption={(props, option) => (
                  <Box component="li" {...props}>
                    {option.label}
                  </Box>
                )}
              />
            </Grid>

            <Grid item xs={3}>          
              <Autocomplete
                disablePortal={false}
                PopperComponent={CustomPopper}
                id="combo-box-status"
                options={allstatuses ? allstatuses.map(status => ({
                  id: status.idstudent_status,
                  label: status.status_name
                })) : []}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                sx={{ flexGrow:1 }}
                renderInput={(params) => <TextField {...params} label="Search Status" inputRef={autocompleteStatusRef} />}
                onChange={(event, newValue) => {
                  onStatusName(newValue);
                }}
                ListboxProps={{ style: { maxHeight:'200px', overflow:'auto' } }}
                renderOption={(props, option) => (
                  <Box component="li" {...props}>
                    {option.label}
                  </Box>
                )}
              />
            </Grid>
          </Grid>
        </Stack>
      )}

      {numSelected > 1 ? (
        <Tooltip title={t('Attendances')}>
          <IconButton>
            <SvgIcon component={Icon_Attendance} sx={{ mr:2 }} />
          </IconButton>
        </Tooltip>
      ) : (
        // Burada ikonu FilterList yerine Filter Clean (Clear All) ikonuna dönüştürüyoruz.
        <Tooltip title="Clear Filters">
          <IconButton onClick={handleClearFilters}>
            <Iconify icon="ic:round-clear-all" /> {/* "Filter Clean" i ifade edecek bir icon */}
          </IconButton>
        </Tooltip>
      )}
    </StyledRoot>
  );
}