import { Helmet } from 'react-helmet-async';
import { useLoading } from '../../utils/contexts/LoadingContext';
import { filter } from 'lodash';
import { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import DynamicSelect from '../../components/select/dynamicSelect';
import { useTranslation } from 'react-i18next'; //Multi Language
//import formatDateTime from '../../../src/utils/formatDateTime';
import _ from 'lodash';

// @mui
import {
  Card, Table, Stack, Paper, Avatar, Button, Popover, Checkbox, TableRow,
  MenuItem, TableBody, TableCell, Container, Typography, IconButton,
  TableContainer, TablePagination, Snackbar, Alert, Divider,
  Dialog, DialogTitle, DialogContent, DialogActions, Tooltip
} from '@mui/material';
import { SvgIcon } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';

// components
import Label from '../../components/label';
import Iconify from '../../components/iconify';
import { ReactComponent as Icon_Attendance } from '../../assets/icons/attendance.svg';
import { ReactComponent as Icon_Payment } from '../../assets/icons/icon_payment.svg';
import { ReactComponent as Icon_Extract } from '../../assets/icons/extract.svg';
import { ReactComponent as Icon_Transaction } from '../../assets/icons/transaction.svg';

import Scrollbar from '../../components/scrollbar'
// sections
import { StudentListHead, StudentListToolbar } from '../../sections/@dashboard/student';

//database
import { getStudent, getStudents } from '../../_data/student/getStudent';
import ReportStudentAttendances from '../../_data/student/getReportParticipant';
import SessionExpiredDialog from '../../components/session/sessionExpried';
import DataDisplay from './CustomDataDisplay';
import { getBatchNames } from '../../_data/batchs/getBatchNames';
import CreateStudentDialog from '../../components/student/create-student-dialog';
import GetCourseCoordinatorTasks from '../../components/course-coortination/coursecoordinationtasks';
import UpdateStudentDialog from '../../components/student/update-student-dialog';
import StudentDetailsDialog from '../../components/student/student-details-dialog';
import DeleteStudent from '../../_data/student/deleteStudent';
import getSession from '../../utils/getSession'; //Session Management
import clearSession from '../../utils/clearSession';
import getNextTransactions from '../../_data/student/getNextTransactions';
import getFirstStudentRecords from '../../_data/student/getFirstStudentRecords';
import getToDateFirstContacts from '../../_data/student/getToDateFirstContacts';
import updateChangeFirstMail from '../../_data/student/updateFirstMail';
//import getCoordinatorsTotalTasks from '../../_data/student/getCoordinatorsTotalTasks';
import CreateFirstInteractionDialog from '../../components/student/create-first-Interaction-Task';

import config from '../../config';
const { PUBLIC_URL } = config;
const TransactionIcon = `${PUBLIC_URL}/assets/icons/navbar/ic_transaction.svg`;



function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) return -1;
  if (b[orderBy] > a[orderBy]) return 1;
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(
  array,
  comparator,
  query,
  filterStatusId,
  filterBatchName,
  filterCoordinatorId,
  filterCallInDays,
  filterFirstRecord,
  firstStudentRecords,
  nextTransactions,
  toDateFirstContactRecords,
  filterReferenceId,
  filterAssessmentLevel
) {
  if (!Array.isArray(array)) {
    return [];
  }

  const stabilizedThis = array?.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  const toDateFirstContactIds = toDateFirstContactRecords.map(item => item.studentid);
  return stabilizedThis.map((el) => el[0]).filter((student) => {
    const lowerCaseQuery = String(query).toLowerCase();
    const matchQuery = query
      ? Object.values(student).some((value) =>
          String(value).toLowerCase().includes(lowerCaseQuery)
        )
      : true;

    const matchStatus = filterStatusId ? student?.status === filterStatusId : true;
    const matchBatch = filterBatchName ? student?.coursename === filterBatchName : true;
    const matchCoordinators = filterCoordinatorId ? student.follower === filterCoordinatorId : true;

    const matchCallInDays = filterCallInDays && nextTransactions && nextTransactions.length > 0
      ? nextTransactions.includes(student.idstudents)
      : !filterCallInDays; 

    const matchFirstContact = toDateFirstContactIds && toDateFirstContactIds.length > 0
    ? toDateFirstContactIds.includes(student.idstudents)
    : true; 

    const matchFirstRecord = filterFirstRecord
    ? firstStudentRecords.includes(student.idstudents)
    : true;

    const matchReference = filterReferenceId
    ? (student?.allreferences 
      && student?.allreferences?.References 
      && student?.allreferences?.References.includes(filterReferenceId))
    : true;
      
    const matchAssessmentLevel = filterAssessmentLevel
    ? (student?.assessmentlevelname       
      && student?.assessmentlevelname.includes(filterAssessmentLevel))
    : true;

    return matchQuery && matchStatus && matchBatch && matchCoordinators && matchFirstRecord && matchCallInDays && matchFirstContact && matchReference && matchAssessmentLevel;
  });
}

export default function StudentPage() {
  const { showLoading, hideLoading } = useLoading();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [showFilters, setShowFilters] = useState(true);

  const TABLE_HEAD = [
    { id: 'namesurname', label: t('NameSurname'), alignRight: false },
    { id: 'totalcontacts', label: t('TotalContact'), alignRight: false },
    { id: 'status', label: t('Status'), alignRight: false },
    { id: 'assessmentlevelname', label: t('Assessment Level'), alignRight: false },
    { id: 'allreferences', label: t('All References'), alignRight: false },
    { id: 'contact', label: t('Contact'), alignRight: false },
    { id: 'follower', label: t('Follower'), alignRight: false },
    { id: 'futurecontactdate', label: t('Future Contact'), alignRight: false },
    // { id: 'coursename', label: t('CourseName'), alignRight: false },
    { id: '' },
  ];

  const [open, setOpen] = useState(null);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('coursename');
  const [filterStudentId, setfilterStudentId] = useState('');
  const [filterStatusId, setfilterStatusId] = useState('');
  const [filterBatchName, setfilterBatchName] = useState('');
  const [filterCoordinatorId, setfilterCoordinatorId] = useState('');
  const [filterReferenceId, setfilterReferenceId] = useState('');
  const [filterAssessmentLevel, setfilterAssessmentLevel] = useState('');
  const [filterCallInDays, setfilterCallInDays] = useState(false);
  const [filterFirstRecord, setfilterFirstRecord] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [studentsDatas, setStudentsDatas] = useState([]);
  const [recordsNumber, setrecordsNumber] = useState(0);
  const [msgOpen, setMsgOpen] = useState(false);
  const [severity, setSeverity] = useState('success');
  const [alertMessage, setAlertMessage] = useState('');
  const [nextTransactions, setNextTransactions] = useState([]);
  const [firstStudentRecords, setFirstStudentRecords] = useState([]);
  const [toDateFirstContactRecords, setToDateFirstContactRecords] = useState([]);
  const [firstContactDate, setFirstContactDate] = useState();
  const [originalData, setOriginalData] = useState([]);
  const [isSessionExpired, setIsSessionExpired] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [openConfirmExtractDialog, setOpenExtractConfirmDialog] = useState(false);
  const [openFirstTaskExtractDialog, setOpenFirstTaskExtractDialog] = useState(false);
  const [currentAction, setCurrentAction] = useState();
  const updateDialogRef = useRef();
  const studentDetailsDialogRef = useRef();
  const [selectedStudentData, setSelectedStudentData] = useState(null);
  const [newRecordAdded, setNewRecordAdded] = useState(false);



  const handleSessionExpiration = () => {
    setIsSessionExpired(true);
  };

  useEffect(() => {
    if (isSessionExpired) {
        setSeverity('error');
        setAlertMessage('Your session has expired. Please login again.');
        setMsgOpen(true);
    }
  }, [isSessionExpired]);



  const getstudentsDatas = async () => {
    try {
      showLoading();
      const data = await getStudents();
      console.log("STUDENT[0]: ", data[0]);
      setrecordsNumber(data.length);
      setStudentsDatas(data);
      setOriginalData(data);
    } catch (error) {
      console.error(error);
      setOriginalData([]);
      setStudentsDatas([]);
    } finally {
      hideLoading();
    }
  };

  const loadNextTransactions = async () => {
    try {
      const response = await getNextTransactions();
      setNextTransactions(response);
    } catch (error) {
      console.error('Error loading loadNextTransactions:', error);
    }
  };

  const loadFirstStudentRecords = async () => {
    try {
      const response = await getFirstStudentRecords();
      setFirstStudentRecords(response);
    } catch (error) {
      console.error('Error loading firstStudentRecords:', error);
    }
  };

  const loadToDateFirstContactRecords = async () => {
    try {
      if (firstContactDate) {
        const selectedDate = firstContactDate.toISOString().split('T')[0];
        const response = await getToDateFirstContacts(selectedDate);
        setToDateFirstContactRecords(response);
      } else {
        setToDateFirstContactRecords([]);
      }
    } catch (error) {
      console.error('Error loading toDateFirstContactRecords:', error);
    }
  };

  const filteredStudents = applySortFilter(
    studentsDatas,
    getComparator(order, orderBy),
    filterStudentId,
    filterStatusId,
    filterBatchName,
    filterCoordinatorId,
    filterCallInDays,
    filterFirstRecord,
    firstStudentRecords,
    nextTransactions,
    toDateFirstContactRecords,
    filterReferenceId,
    filterAssessmentLevel
  );

  useEffect(() => {

    setrecordsNumber(filteredStudents.length);
  }, [filteredStudents]);

  useEffect(() => {
    getstudentsDatas();
  }, []);

  useEffect(() => {
    if (filterCallInDays) {
      loadNextTransactions();
    } else {
      setNextTransactions([]);
    }
  }, [filterCallInDays]);

  useEffect(() => {
    if (filterFirstRecord) {
      loadFirstStudentRecords();
    } else {
      setFirstStudentRecords([]);
    }
  }, [filterFirstRecord]);

  useEffect(() => {
    if (firstContactDate) {      
      loadToDateFirstContactRecords();
    } else {
      setToDateFirstContactRecords([]);
    }
  }, [firstContactDate]);

  useEffect(() => {
    if (filterReferenceId) {      
      loadToDateFirstContactRecords();
    } else {
      setToDateFirstContactRecords([]);
    }
  }, [filterReferenceId]);

  const handleFirstTaskDialogClose = () => {
    setOpenFirstTaskExtractDialog(false);
    refreshStudentData();
  };

  const handleNewStudentAdded = async () => {
    getstudentsDatas();
    setNewRecordAdded(true);
  };

  const handleEditStudent = () => {
    setSelectedStudentData(selected);
    if (updateDialogRef.current) {
      updateDialogRef.current.handleOpen();
    }
  };

  const handleStudentDetails = (getSelected) => {
    try { 
      setSelectedStudentData(getSelected[0]);
      if (studentDetailsDialogRef.current) {
        studentDetailsDialogRef.current.handleOpen();
      }
    } catch (error) {
      console.log("error: ", error);
    }
  }; 

  const handleDownRecord = async (event) => {
    try {
      const label = event.currentTarget.textContent;

      if (selected.length > 0) {
        switch (label) {
          case t('AssignFollower'):
            if (selected[0]?.follower > 0) {
              setSeverity('error');
              setAlertMessage('A new assignment cannot be made to a student who has been assigned a follower!\nYou must make the change from the "Interaction section".');
              setMsgOpen(true);
            } else {
              handleFirstTaskDialog(selected);
            }
            break;
          case t('Details'):
            setSelectedStudentData(selected);
            handleStudentDetails(selected);
            break;
          case t('Delete'):
            handleConfirmDialogOpen(selected);
            break;
          case t('Transaction'):
            handleInterviewTransactionPageOpen(selected[0].id);
            break;
          case t('Attendance'):
            const mail = selected[0].mail;
            handleStudentAttendancePageOpen(mail);
            break;
          case ('AllExtract'):
            setSeverity('warning');
            setAlertMessage('All connections are closed.');
            setMsgOpen(true);
            handleConfirmExtractDialogOpen(selected);
            break;
          default:
            break;
        };
      } else {
        setSeverity('error');
        setAlertMessage('Not selected Meeting Record!');
        setMsgOpen(true);
      }
    } catch (error) {
      console.log("handleDownRecord-Error: ", error);
    }
  };

  const handleFirstTaskDialogOpen = () => {
    setOpenFirstTaskExtractDialog(true);
  };

  const [firstRecordStudentData, setFirstRecordStudentData] = useState({
    nameSurname: '',
    id: '',
    email: ''
  });

  const handleFirstTaskDialog = (select) => {
    setFirstRecordStudentData({
      nameSurname: `${select[0].name} ${select[0].surname}`,
      id: select[0].id,
      email: select[0].mail
    });
    handleFirstTaskDialogOpen();
  };

  const handleOpenMenu = (event, studentData) => {
    setOpen(event.currentTarget);
    setSelected([{
      id: studentData.idstudents,
      mail: studentData.email,
      dateofbirth: studentData.dateofbirth,
      name: studentData.name,
      surname: studentData.surname,
      contact: studentData.contact,
      follower: studentData.follower,
      follower_namesurname: studentData.follower_namesurname,
      graduation: studentData.graduation,
      memberships: studentData.memberships,
      coursename: studentData.coursename,
      status: studentData.status,
      resourcecode: studentData.resourcecode,
      Interninfo: studentData.Interninfo,
      allreferences: studentData.allreferences,
      description: studentData.description,
      idbatch: studentData.idbatch,
      langlevel: studentData.langlevel,
      assessmentlevelname: studentData.assessmentlevelname
    }]);
  };

  const handleCloseMenu = () => {
    setOpen(null);
    setSelected([]);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked && Array.isArray(filteredStudents)) {
      const newSelecteds = filteredStudents.map((n) => ({
        id: n.idstudents,
        mail: n.email,
        contact: n.contact,
        fullname: `${n.name} ${n.surname}`,
      }));
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, student) => {
    const selectedIndex = selected.findIndex((item) => item.id === student.id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, student);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const refreshStudentData = () => {
    getstudentsDatas();
  };

  const performAction = async () => {
    try {
      const ids = currentAction.map((data) => data.id);
      const idsQueryString = ids.join(',');
      const response = await DeleteStudent(idsQueryString);
      if (response.message === 'Student deleted successfully.' && response.affectedRows > 0) {
        setSeverity('success');
        setAlertMessage('Student deleted successfully.');
        setMsgOpen(true);
        getstudentsDatas();
        handleCloseMenu();
      } else if (response.message === 'Cannot delete or update a parent row, because there is dependency child records.') {
        setSeverity('warning');
        setAlertMessage(response.message);
        setMsgOpen(true);
        handleCloseMenu();
      }
    } catch (error) {
      console.log("Error: ", error);
    }
  };

  const handleConfirmDialogOpen = (select) => {
    setCurrentAction(select);
    setOpenConfirmDialog(true);
  };

  const handleConfirmExtractDialogOpen = (select) => {
    setCurrentAction(select);
    setOpenExtractConfirmDialog(true);
  };

  const handleConfirmDialogClose = () => {
    setOpenConfirmDialog(false);
  };

  const handleConfirmExractDialogClose = () => {
    setOpenExtractConfirmDialog(false);
  };

  const handleConfirm = () => {
    performAction(currentAction);
    handleConfirmDialogClose();
  };

  const handleConfirmExtract = () => {
    handleConfirmExractDialogClose();
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setfilterStudentId(event.target.value);
  };

  const handleFilterStatusByName = (newValue) => {
    if (!newValue) {
      setPage(0);
      setfilterStatusId('');
      return;
    }
    setPage(0);
    setfilterStatusId(newValue.id);
  };

  const handleFilterBatchByName = (newValue) => {
    if (!newValue) {
      setPage(0);
      setfilterBatchName('');
      return;
    }
    setPage(0);
    setfilterBatchName(newValue.id);
  };

  const handleFilterCoordinatorByName = (newValue) => {
    if (!newValue) {
      setPage(0);
      setfilterCoordinatorId('');
      return;
    }
    setPage(0);
    setfilterCoordinatorId(newValue.id);
  };

  const handleFilterByReferrenceName = (value) => {
    setPage(0);
    setfilterReferenceId(value?.label || '');
  };

  const handleFilterByAssessmentLevel = (newValue) => {
    setPage(0);
     setfilterAssessmentLevel(newValue?.label || '');
  };

  const handleFilterByCallInDays = (newValue) => {
    setPage(0);
    setfilterCallInDays(newValue || false);
    if (!newValue) {
      setNextTransactions([]);
    }
  };

  const handleFilterByFirstRecord = (newValue) => {
    showLoading();
    setPage(0);
    setfilterFirstRecord(newValue || false);
    if (!newValue) {
      setFirstStudentRecords([]);
    }
    hideLoading();
  };

  const handleFilterByFirstContact = (newValue) => {
    showLoading();
    setPage(0);
    setFirstContactDate(newValue || false);
    if (!newValue) {
      //setFirstStudentRecords([]);
      setFirstContactDate([]);
    }
    hideLoading();
  };

  const handleAllBatchAttendancePageOpen = async () => {
    if (filteredStudents.length > 0) {
      const getEmails = filteredStudents.map((sel) => sel.contact.mail['mailaddress-1']);
      const reportAllBatchZoomAttendances = await ReportStudentAttendances(getEmails);
      navigate(`/dashboard/studentattendances`, { state: { reportAllBatchZoomAttendances, batchName: filterStudentId } });
    }
  };

  const handleStudentAttendancePageOpen = async (email) => {
    const reportStudentZoomAttendances = await ReportStudentAttendances(email);
    navigate(`/dashboard/studentattendances`, { state: { reportStudentZoomAttendances } });
  };

  const handleInterviewTransactionPageOpen = async (selectedId) => {
    navigate(`/dashboard/interviewtransaction`, { state: { selectedId } });
  };

  const replaceDateTime = (dateString) => {
    if (!dateString || typeof dateString !== 'string') {
      return '';
    }
    const formattedDate = dateString.replace('T', ' ').replace('Z', '').slice(0, 16);
    return formattedDate;
  };

  const isPastOrToday = (date) => {
    const berlinTimeString = new Date().toLocaleString('en-US', { timeZone: 'Europe/Berlin' });
    const berlinTime = new Date(berlinTimeString);
    const givenDate = new Date(date);
    const startOfToday = new Date(berlinTime.getFullYear(), berlinTime.getMonth(), berlinTime.getDate(), 0, 0, 0);
    return givenDate <= startOfToday;
  };

  const isNotFound = Array.isArray(filteredStudents) && !filteredStudents.length && (!!filterStudentId || !!filterStatusId || !!filterBatchName || !!filterCoordinatorId || !!filterCallInDays || !!filterFirstRecord || !!filterReferenceId || !!filterAssessmentLevel);

  return (
    <>
      <Helmet>
        <title> {t("Page.Title")} | Oak Academy </title>
      </Helmet>

      <Container maxWidth={false} sx={{ marginLeft: 0, maxWidth: "none" }}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <div>
            <Typography variant="h4" gutterBottom>
              <span
                style={{ fontWeight: "bold", fontSize: "25px", color: "blue" }}
              >
                {t("StudentList")}
              </span>
              &nbsp;&nbsp;
              <span
                style={{ fontStyle: "normal", fontSize: "15px", color: "gray" }}
              >
                {" "}
                {t("TotalRecords")} {`: ${recordsNumber} `}
              </span>
            </Typography>
          </div>
        </Stack>
        <Stack direction="row" spacing={2} mt={3}>
          <Button
            variant="outlined"
            onClick={() => setShowFilters(!showFilters)}
          >
            {showFilters ? "Hide Filters" : "Show Filters"}
          </Button>
          <CreateStudentDialog onStudentAdded={handleNewStudentAdded} />
          <UpdateStudentDialog ref={updateDialogRef} initialData={selectedStudentData} onStudentUpdate={refreshStudentData}/>
          <StudentDetailsDialog ref={studentDetailsDialogRef} selectedStudent={selectedStudentData}/>
        </Stack>
        {showFilters && (
          <Stack spacing={2} mb={3}>
            <GetCourseCoordinatorTasks
              selectedCoordinatorId={filterCoordinatorId}
              isRefresh={newRecordAdded}
            />
            <Card>
              <StudentListToolbar
                numSelected={selected.length}
                filterName={filterStudentId}
                onFilterName={handleFilterByName}
                onStatusName={handleFilterStatusByName}
                onBatchName={handleFilterBatchByName}
                onCoordinatorName={handleFilterCoordinatorByName}
                onCallInDays={handleFilterByCallInDays}
                onFirstRecord={handleFilterByFirstRecord}
                onFirstContact={handleFilterByFirstContact}
                onReferenceName={handleFilterByReferrenceName}
                onAssessmentLevel={handleFilterByAssessmentLevel}
              />
            </Card>
          </Stack>
        )}

        {/* Table with sticky header */}
        <Card>
          <Divider sx={{ mb: 0 }} />
          {/* Burada maxHeight veriyoruz ve Table'a stickyHeader ekliyoruz */}
          <Scrollbar>
            <TableContainer sx={{ maxHeight: 600 }}>
              <Table stickyHeader>
                <StudentListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={recordsNumber}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {(rowsPerPage === -1
                    ? filteredStudents
                    : filteredStudents.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                  ).map((row, index) => {
                    const {
                      idstudents,
                      email,
                      coursename,
                      name,
                      surname,
                      avatarUrl,
                      contact,
                      follower,
                      follower_namesurname,
                      status,
                      memberships,
                      allreferences,
                      futurecontactdate,
                      assessmentlevelname,
                      totalcontacts
,
                    } = row;

                    const isItemSelected = selected.some(
                      (item) => item.id === idstudents
                    );
                    return (
                      <TableRow
                        hover
                        key={index}
                        tabIndex={-1}
                        role="checkbox"
                        selected={isItemSelected}
                        sx={{
                          backgroundColor:
                            assessmentlevelname === "Student"
                              ? "lightblue"
                              : "inherit",
                        }}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            checked={isItemSelected}
                            onChange={(event) =>
                              handleClick(event, {
                                id: idstudents,
                                mail: email,
                                contact: contact,
                                follower: follower,
                                follower_namesurname: follower_namesurname,
                                memberships: memberships,
                                allreferences: allreferences,
                                fullname: `${name} ${surname}`,
                                totalcontacts: totalcontacts,
                              })
                            }
                          />
                        </TableCell>
                        <TableCell component="th" scope="row" padding="none">
                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing={2}
                          >
                            <Avatar alt={String(idstudents)} src={avatarUrl} />
                            <Typography variant="subtitle2" noWrap>
                              {`${name} ${surname}`}
                            </Typography>
                          </Stack>
                        </TableCell>
                        <TableCell align="left">{totalcontacts}</TableCell>
                        <TableCell align="left">
                          <Label
                            sx={{
                              color:
                                status == 1
                                  ? "success.main"
                                  : status == 3
                                  ? "warning.main"
                                  : status == 4
                                  ? "primary.main"
                                  : status == 5
                                  ? "error.main"
                                  : status == 6
                                  ? "mycolor.main"
                                  : status == 7
                                  ? "info.main"
                                  : "error",
                            }}
                          >
                            {status == 1
                              ? t("RegisteredStudent")
                              : status == 2
                              ? t("InternStudent")
                              : status == 3
                              ? t("TimedAccessGranted")
                              : status == 4
                              ? t("ProspectiveStudent")
                              : status == 5
                              ? t("RelationshipEnded")
                              : status == 6
                              ? t("LMS-HybridRegistered")
                              : status == 7
                              ? t("LMSRegistered")
                              : t("RelationshipEnded")}
                          </Label>
                        </TableCell>
                        <TableCell align="left">
                          {assessmentlevelname}
                        </TableCell>
                        <TableCell align="left">
                          {allreferences &&
                            allreferences.References &&
                            allreferences.References.map((ref, index) => (
                              <div
                                key={index}
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  marginBottom: "8px",
                                }}
                              >
                                <span
                                  style={{ color: "blue", marginRight: "8px" }}
                                >
                                  •
                                </span>
                                {ref}
                              </div>
                            ))}
                        </TableCell>
                        <TableCell align="left">
                          <DataDisplay customData={contact} />
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={
                            follower_namesurname === "First Record"
                              ? {
                                  fontWeight: "bold",
                                  fontSize: "16px",
                                  color: "purple",
                                }
                              : {}
                          }
                        >
                          {follower_namesurname}
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={
                            isPastOrToday(replaceDateTime(futurecontactdate))
                              ? { color: "red", fontWeight: "bold" }
                              : { color: "green", fontWeight: "normal" }
                          }
                        >
                          {replaceDateTime(futurecontactdate)}
                        </TableCell>
                        {/* <TableCell align="left">{coursename}</TableCell> */}
                        <TableCell align="right">
                          <IconButton
                            size="large"
                            color="inherit"
                            onClick={(event) => {
                              handleOpenMenu(event, { ...row });
                            }}
                          >
                            <Iconify icon={"eva:more-vertical-fill"} />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {(rowsPerPage === -1
                    ? filteredStudents.length
                    : filteredStudents.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      ).length) === 0 && (
                    <TableRow style={{ height: 53 }}>
                      <TableCell colSpan={9} align="center">
                        {isNotFound ? (
                          <Paper sx={{ textAlign: "center" }}>
                            <Typography variant="h6" paragraph>
                              Not found
                            </Typography>
                            <Typography variant="body2">
                              No results found for &nbsp;
                              <strong>&quot;{filterStudentId}&quot;</strong>.
                              <br /> Try checking for typos or using complete
                              words.
                            </Typography>
                          </Paper>
                        ) : null}
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50, { value: -1, label: "All" }]}
            component="div"
            count={recordsNumber}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: "top", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        PaperProps={{
          sx: {
            p: 1,
            width: 205,
            "& .MuiMenuItem-root": {
              px: 1,
              typography: "body2",
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem onClick={handleDownRecord} sx={{ color: "text.dark", Width: 300 }}>
          <Iconify icon={"eva:checkmark-square-2-outline"} sx={{ mr: 2 }} />
          {t("AssignFollower")}
        </MenuItem>

        <Tooltip title={t("InterviewTransactions")}>
          <MenuItem onClick={handleDownRecord} sx={{ color: "success.dark", Width: 300 }}>
            <Iconify icon={"eva:swap-outline"} sx={{ mr: 2 }} />
            {t("Transaction")}
          </MenuItem>
        </Tooltip>

        <MenuItem onClick={handleDownRecord} sx={{ color: "secondary.main", minWidth: 300 }}>
          <Iconify icon={"eva:more-vertical-fill"} sx={{ mr: 2 }} />
          {t("Details")}
        </MenuItem>

        <MenuItem onClick={handleEditStudent} sx={{ color: "primary.main", minWidth: 300 }}>
          <Iconify icon={"eva:edit-2-fill"} sx={{ mr: 2 }} />
          {t("Edit")}
        </MenuItem>
        <MenuItem onClick={handleDownRecord} sx={{ color: "error.main", minWidth: 300 }}>
          <Iconify icon={"eva:trash-2-outline"} sx={{ mr: 2 }} />
          {t("Delete")}
        </MenuItem>
      </Popover>

      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={msgOpen}
        autoHideDuration={5000}
        onClose={() => setMsgOpen(false)}
      >
        <Alert
          severity={severity}
          onClose={() => setMsgOpen(false)}
          sx={{
            width: 480,
            minWidth: 350,
            minheight: 100,
            height: 130,
            fontSize: 14,
            fontStyle: "normal",
            whiteSpace: "pre-line",
          }}
        >
          <Typography style={{ whiteSpace: "pre-line" }}>
            {alertMessage}
          </Typography>
        </Alert>
      </Snackbar>

      <Dialog
        open={openConfirmDialog}
        onClose={handleConfirmDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Are you sure?"}</DialogTitle>
        <DialogContent>
          {selected.map((select, index) => (
            <p
              key={index}
              style={{ fontWeight: "bold", color: "#1640D6", marginBottom: 2 }}
            >
              {select.fullname}
              {" /  "}
              {select.mail}
              {"  "}
            </p>
          ))}
          <p>
            The registration of the student(s) whose above information is visible
            will be deleted.
            <br />
            Are you sure you want to do this?
          </p>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirmDialogClose} color="primary">
            Cancel
          </Button>
          <Button
            onClick={handleConfirm}
            autoFocus
            style={{
              backgroundColor: "red",
              color: "#FFFFFF",
            }}
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openConfirmExtractDialog}
        onClose={handleConfirmExractDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Are you sure?"}</DialogTitle>
        <DialogContent>
          {selected.map((select, index) => (
            <p
              key={index}
              style={{ fontWeight: "bold", color: "#1640D6", marginBottom: 2 }}
            >
              {select.fullname}
              {" /  "}
              {select.mail}
              {"  "}
            </p>
          ))}
          <p>
            The interest and registration of the student(s) whose information is
            shown above will be removed from all platforms.
          </p>
          <br />
          Are you sure you want to do this?
        </DialogContent>
        <DialogActions>
          <Button sx={{ pr: 10 }} onClick={handleConfirmExractDialogClose} color="primary">
            Cancel
          </Button>
          <Button
            sx={{ pr: 10, textAlign: "center" }}
            onClick={handleConfirmExtract}
            autoFocus
            style={{
              backgroundColor: "red",
              color: "#FFFFFF",
            }}
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>

      {openFirstTaskExtractDialog && (
        <CreateFirstInteractionDialog
          onTransactionAdded={handleNewStudentAdded}
          studentNameSurname={{ Name: firstRecordStudentData.nameSurname }}
          studentId={firstRecordStudentData.id}
          email={firstRecordStudentData.email}
          openDialog={openFirstTaskExtractDialog}
          onClose={handleFirstTaskDialogClose}
        />
      )}
    </>
  );
};