import axios from 'axios';
import config from '../../config';
import getToken from '../api/getToken';
const { BaseUrl } = config;

const updateWebContactForms = async (id, contact_status, rejectReason) => {
  try {
    const token = await getToken();
    //console.log("updateWebContactForms: ", id, status, rejectReason);
    const response = await axios.patch(`${BaseUrl}/contact/updatewebcontactform`, 
      { id: id, status: contact_status, rejectReason: rejectReason},
      {
        headers: {
          'Authorization': `Bearer ${token}`
        },
    });
    const info = response || [];
    return info;
  } catch (error) {
      return [];
  }
};

export { updateWebContactForms };