import axios from 'axios';
import config from '../../config';
import getToken from '../api/getToken';
const { BaseUrl } = config;

const createEmployeeLeaves = async (iduser, type, startdata, enddate, reason) => {
  try {
    const token = await getToken();
    const response = await axios.post(`${BaseUrl}/hr/insertemployeeleaves`, 
      { 
        userid: iduser,
        leavetype: type, 
        startData: startdata, 
        endDate: enddate, 
        reason: reason },
      {
        headers: {
          'Authorization': `Bearer ${token}`
        },
    });
    const info = response || [];
    return info;
  } catch (error) {
    console.log("Error: ", error);
      return [];
  }
};

export { createEmployeeLeaves };