import React, { useState, memo, useEffect } from 'react';
import { Card, CardContent, Typography, TextField, Divider, Checkbox, Box, 
FormControlLabel, InputAdornment, Snackbar, Alert } from '@mui/material';
import { ArrowUpward, ArrowDownward, CheckBox } from '@mui/icons-material';


const CustomPaymentOptions = ({ options, regitrationfee, paymentType, customPaymentContract }) => { 

  const [monthsBefore, setMonthsBefore] = useState(0);
  const [amountBefore, setAmountBefore] = useState(50);
  const [monthsAfter, setMonthsAfter] = useState(0);
  const [amountAfter, setAmountAfter] = useState(50);
  const [amountOneTime, setAmountOneTime] = useState(0);
  const [cashAmount, setCashAmount] = useState(0);
  const [cashCourseRegistrationFee, setCashCourseRegistrationFee] = useState(0);
  const [selectOptions, setSelectOptions] = useState(options);
  // const [selectPaymentType, setSelectPaymentType] = useState(options,paymentType.paymentType);
  const [selectPaymentType, setSelectPaymentType] = useState(() => {
    if (!paymentType || !paymentType.paymentType) {
      //console.warn('paymentType veya paymentType.paymentType tanımlı değil:', paymentType);
      //alert("Please enter country of candidate information first!");
      showMessage('lease enter country of candidate information first!', 'error');
      return null; // Eğer değerler eksikse başlangıç değeri null olur
    }
    return paymentType.paymentType; // Değerler tanımlıysa atama yapılır
  });
  const [msgOpen, setMsgOpen] = useState(false);
  const [severity, setSeverity] = useState('success');
  const [alertMessages, setAlertMessages] = useState([]);
  const showMessage = (message, severityType = 'warning', duration = 8000) => {
    const id = new Date().getTime();
    const newMessage = { id, message, severityType };
    setAlertMessages(prevMessages => [...prevMessages, newMessage]);
    setSeverity(severityType);
    setMsgOpen(true);
    setTimeout(() => {
      setAlertMessages(prevMessages => prevMessages.filter(msg => msg.id !== id));
    }, duration);
  };


  const [checkedCourseFee, setCheckedCourseFee] = useState(true);

  // Eksik Bilgi Kontrolü
  // useEffect(() => {
  //   if (!options || !paymentType || !paymentType.paymentType) {
  //     //console.warn("Incomplete information:", options, paymentType);
  //     //alert("Please enter country of candidate information first!");
  //     showMessage('lease enter country of candidate information first!', 'error');
  //     return null;
  //   }
    
  // }, [options, paymentType]);

  const [customPaymentContractData, setCustomPaymentContractData] = useState({
    registrationfee: 0, //Ilk kayit ucreti
    paymentfirstdetails: {  // Pesin odemede bu alandaki cashamount degeri dolu gelir Installmentda ise bu alan sifir gelir taksit ay ve odeme bilgisi dolu gelir.
      installmentcount: monthsBefore,
      installmentamount: amountBefore,
      cashamount:  cashAmount,
      totalfirstamount: (monthsBefore * amountBefore)
    },
    paymentseconddetails:{  //Ise basladiktan sonraki secimi ifade etmektedir.
      installmentcount1: monthsAfter,
      installmentamount1: amountAfter,
      totalsecondamount: (monthsAfter * amountAfter),
      description: '',
      onetimepayment: amountOneTime
    },
  });

  useEffect(() => {
    const selectedCoursefee = checkedCourseFee ? regitrationfee.fee : 0;
    const updatedContract = {
      selectedCoursefee,
      monthsBefore,
      amountBefore,
      monthsAfter,
      amountAfter,
      totalBefore: monthsBefore * amountBefore,
      totalAfter: monthsAfter * amountAfter,
      onetimepayment: amountOneTime,
    };
    customPaymentContract(updatedContract);
  }, [monthsBefore, amountBefore, monthsAfter, amountAfter, checkedCourseFee, amountOneTime]);
  
  useEffect(() => {
    //const selectedCoursefee = checkedCourseFee ? regitrationfee.fee : 0;
    const updatedContract = {
      selectedCoursefee : Number(cashCourseRegistrationFee),      
      cashamount: Number(cashAmount),
      totalBefore: Number(cashAmount) + Number(cashCourseRegistrationFee)
    };
    customPaymentContract(updatedContract);
  }, [cashCourseRegistrationFee, cashAmount]);


  const handleMonthsChangeBefore = (delta) => {
    setMonthsBefore((prev) => {
        const newValue = prev + delta;
        return Math.max(0, Math.min(newValue, 24));
      });
  };

  const handleMonthsChangeAfter = (delta) => {
    setMonthsAfter((prev) => {
        const newValue = prev + delta;
        return Math.max(0, Math.min(newValue, 24));
      });
  };

  const handleAmountChangeBefore = (delta) => {
    setAmountBefore((prev) => {
        const newValue = prev + delta;
        return Math.max(50, Math.min(newValue, 1000));
      });
  };

  const handleAmountChangeAfter = (delta) => {
    setAmountAfter((prev) => {
        const newValue = prev + delta;
        return Math.max(50, Math.min(newValue, 1000));
      });
  };

  const handleAmountChangeOneTime = (delta) => {
    setAmountOneTime((prev) => {
        const newValue = prev + delta;
        // return Math.max(100, Math.min(newValue, 1000));
        return newValue; //TODO: Burada min deger hazirlanacak mi?
      });
  };

  // const handleChangeCashCourseRegistrationFee = (event) => {
  //   const value = event.target.value;
  //   const regex = /^(?!0\d)\d*(\.\d{0,2})?$/;
  //   if (value !== '' & regex.test(value)) {
  //     setCashCourseRegistrationFee(value);
  //   } else {
  //     setCashCourseRegistrationFee('0');
  //   }
  // };
  const handleChangeCashCourseRegistrationFee = (event) => {
    const value = event.target.value;
    const regex = /^(?!0\d)\d*(\.\d{0,2})?$/;
    if (value !== '' && regex.test(value)) {
      const formattedValue = parseFloat(value).toFixed(2);
      setCashCourseRegistrationFee(formattedValue);
    } else {
      setCashCourseRegistrationFee('0.00');
    }
  };
  

  const handleChangeCashAmount = (event) => {
    const value = event.target.value;
    const regex = /^(?!0\d)\d*(\.\d{0,2})?$/;
    if (value !== '' && regex.test(value)) {
      const formattedValue = parseFloat(value).toFixed(2);
      setCashAmount(formattedValue);
    } else {
      setCashAmount('0.00');
    }
  };

  // const handleBlurCashAmount = () => {
  //   if (cashAmount !== '') {
  //     setCashAmount(parseFloat(cashAmount).toFixed(2));
  //   }
  // };

  const handleChangeOneTimeAmount = (event) => {
    const value = event.target.value;
    //console.log('value', value);
    const regex = /^(?!0\d)\d*(\.\d{0,2})?$/;
    if (value === '' || regex.test(value)) {
        setAmountOneTime(value);
    }
  };

  const handleBlurOneTimeAmount = () => {
    if (amountOneTime !== '') {
        setAmountOneTime(parseFloat(amountOneTime).toFixed(2));
    }
  };

  const handleChangeCourseFee = (event) => {
    setCheckedCourseFee(event.target.checked);
  };

  return (
    <>
        { paymentType === 'INSTALLMENT' & selectOptions === 'CustomOptions'  ? (
            <Card sx={{ width: '100%', maxWidth: '600px', margin: '0 auto' }}>
            <CardContent>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                <Typography variant="h6" sx={{ mr: 1 }}>Course Registration Fee ({regitrationfee.currency}) :</Typography>
                {/* {console.log('checkedCourseFee: ', checkedCourseFee)} */}
                    <TextField
                        type="number"
                        variant="outlined"
                        size="small"
                        defaultValue={regitrationfee.fee}
                        value={checkedCourseFee ? regitrationfee.fee : 0} 
                        inputProps={{ style: { textAlign: 'center' },  readOnly: true }}
                        sx={{ width: '113px', mr: 2 }}
                    />

                <FormControlLabel
                    control={<Checkbox defaultValue={true} checked={checkedCourseFee} onChange={handleChangeCourseFee} />}
                    label="include Course fee"
                />
                </Box>
                <Divider sx={{ my: 1 }} />
                <Box sx={{ mt: 2, mb: 2 }}>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                    <Typography variant="subtitle1" sx={{ mr: 1 }}>Months:</Typography>
                    <TextField
                    type="number"
                    variant="outlined"
                    size="small"
                    defaultValue={1}
                    value={monthsBefore}
                    onKeyDown={(event) => event.preventDefault()} // Klavye girişini engelle
                    inputProps={{ style: { textAlign: 'center' }, min: 0, max: 24 }} // Metni ortalamak için stil
                    onChange={handleMonthsChangeBefore}
                    InputProps={{
                        endAdornment: (
                        <InputAdornment position="end">
                            <ArrowUpward sx={{ color: 'green', cursor: 'pointer' }} onClick={() => handleMonthsChangeBefore(1)} />
                            <ArrowDownward sx={{ color: 'red', cursor: 'pointer' }} onClick={() => handleMonthsChangeBefore(- 1)} />
                        </InputAdornment>
                        ),
                    }}
                    sx={{ width: '105px' }}
                    />
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                    <Typography variant="subtitle1" sx={{ mr: 1 }}>Amount (€) :</Typography>
                    <TextField
                    type="number"
                    variant="outlined"
                    size="small"
                    value={amountBefore}
                    defaultValue={50}
                    onKeyDown={(event) => event.preventDefault()}
                    inputProps={{ style: { textAlign: 'center' }, min: 50, max: 1000 }}
                    onChange={handleAmountChangeBefore}
                    InputProps={{
                        endAdornment: (
                        <InputAdornment position="end">
                            <ArrowUpward sx={{ color: 'green', cursor: 'pointer' }} onClick={() => handleAmountChangeBefore(50)} />
                            <ArrowDownward sx={{ color: 'red', cursor: 'pointer' }} onClick={() => handleAmountChangeBefore(- 50)} />
                        </InputAdornment>
                        ),
                    }}
                    sx={{ width: '125px' }}
                    />
                </Box>
                <Typography variant="subtitle1" sx={{ color: 'orange', fontWeight: 'bold' }}>Total (€) :</Typography>
                <TextField
                    type="number"
                    variant="outlined"
                    size="small"
                    value={monthsBefore * amountBefore}
                    InputProps={{ readOnly: true }}
                    sx={{ mb: 2, width: '100px' }}
                />
                </Box>
                <Divider sx={{ my: 1 }} />
                <Typography variant="subtitle1" sx={{ backgroundColor: '#7C00FE', color: 'white', padding: '2px', borderRadius: '4px' }}>After Starting The Job</Typography>
                <Box sx={{ mt: 2, mb: 2 }}>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                    <Typography variant="subtitle1" sx={{ mr: 1 }}>Months:</Typography>
                    <TextField
                    type="number"
                    variant="outlined"
                    size="small"
                    value={monthsAfter}
                    onKeyDown={(event) => event.preventDefault()}
                    inputProps={{ style: { textAlign: 'center' }, min: 0, max: 24 }}
                    onChange={handleMonthsChangeAfter}
                    InputProps={{
                        endAdornment: (
                        <InputAdornment position="end">
                            <ArrowUpward sx={{ color: 'green', cursor: 'pointer' }} onClick={() => handleMonthsChangeAfter(1)} />
                            <ArrowDownward sx={{ color: 'red', cursor: 'pointer' }} onClick={() => handleMonthsChangeAfter(- 1)} />
                        </InputAdornment>
                        ),
                    }}
                    sx={{ width: '105px' }}
                    />
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                    <Typography variant="subtitle1" sx={{ mr: 1 }}>Amount (€) :</Typography>
                    <TextField
                    type="number"
                    variant="outlined"
                    size="small"
                    value={amountAfter}
                    onKeyDown={(event) => event.preventDefault()}
                    onChange={handleAmountChangeAfter}
                    InputProps={{
                        endAdornment: (
                        <InputAdornment position="end">
                            <ArrowUpward sx={{ color: 'green', cursor: 'pointer' }} onClick={() => handleAmountChangeAfter(50)} />
                            <ArrowDownward sx={{ color: 'red', cursor: 'pointer' }} onClick={() => handleAmountChangeAfter(- 50)} />
                        </InputAdornment>
                        ),
                    }}
                    sx={{ width: '125px' }}
                    />
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                    <Typography variant="subtitle1" sx={{ color: 'orange', fontWeight: 'bold', mr: 1, mb: 1}}>Total (€) :</Typography>
                    <TextField
                        type="number"
                        variant="outlined"
                        size="small"
                        value={monthsAfter * amountAfter}
                        InputProps={{ readOnly: true }}
                        sx={{ mb: 2, width: '100px' }}
                    />
                </Box>
                </Box>
                <Divider sx={{ my: 1 }} />
                    <Typography variant="subtitle1" sx={{ color: 'blue' }}>One Time Payment</Typography>
                <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
                    <Typography variant="subtitle1" sx={{ color: 'orange', fontWeight: 'bold', mr:1, mb: 1 }}>Total (€) :</Typography> 
                    {/* 
                        TODO: Total (€) : degeri ulkeye gore degisiyor. Almanya icin € ama Ingiltere icin £ bu nedenle dinamik olmali!!!
                    */}
                    <TextField
                    type="number"
                    variant="outlined"
                    defaultValue={160}
                    size="small"
                    value={amountOneTime}
                    onChange={handleChangeOneTimeAmount}
                    onBlur={handleBlurOneTimeAmount}
                    sx={{ mb: 2, width: '100px' }}
                    />
                </Box>
            </CardContent>
            </Card>
        ):( paymentType === 'CASH' & selectOptions === 'CustomOptions'  ? (
            <Card sx={{ width: '100%', maxWidth: '600px', margin: '0 auto', padding: '16px' }}>
                <CardContent>
                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                      <Typography variant="h6" sx={{ mr: 1, flex: 1 }}>Course Registration Fee (€) :</Typography>
                      <TextField
                          type="text"
                          variant="outlined"
                          size="small"
                          defaultValue={0}
                          value={cashCourseRegistrationFee}
                          onChange={handleChangeCashCourseRegistrationFee}
                          inputProps={{ style: { textAlign: 'center' } }}
                          sx={{ width: '80px' }}
                      />
                    </Box>
                    <Divider sx={{ my: 1 }} />
                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                        <Typography variant="subtitle1" sx={{ mr: 1, color: 'red', fontWeight: 'bold', flex: 1 }}>Total (€) :</Typography>
                        <TextField
                            type="text"
                            variant="outlined"
                            //size="small"
                            value={cashAmount}
                            sx={{ width: '110px', fontWeight: 'bold' }}
                            onChange={handleChangeCashAmount}
                            //onBlur={handleBlurCashAmount}
                            inputProps={{ style: { textAlign: 'center' } }}
                        />
                    </Box>
                </CardContent>
            </Card>
        ):null) }
        {alertMessages.map((msg, index) => (
          <Snackbar
            key={msg.id} 
            open={msgOpen} 
            autoHideDuration={6000} 
            onClose={() => {
              setAlertMessages(prevMessages => prevMessages.filter(message => message.id !== msg.id));
            }}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            sx = {{
              position: 'fixed',
              bottom: `${index * 120}px`,
              left: 0,
              right: 0,
              width: 'auto'
            }}
          >
            <Alert severity={msg.severityType}>
              {msg.message}
            </Alert>
          </Snackbar>
        ))}
    </>
  );
};

export default memo(CustomPaymentOptions);