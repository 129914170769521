import React, { useState, useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Card, CardContent, Typography, Grid, Box, CircularProgress,
Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField  } from '@mui/material';
import { getContactDatas } from '../../_data/contact/getContactDatas';
import { createNewWebStudent } from '../../_data/contact/createnewwebstudent';
import { updateWebContactForms } from '../../_data/contact/updatewebcontactform';
import MessageBox from '../../utils/contexts/MessageBox';
import { format } from 'date-fns';
import { Height } from '@mui/icons-material';

const WebContactForms = () => {
  const [formData, setFormData] = useState([]);
  const [summary, setSummary] = useState({});
  const [selectedRow, setSelectedRow] = useState(null);
  const [loading, setLoading] = useState(true);
  const [msgOpen, setMsgOpen] = useState(false);
  const [severity, setSeverity] = useState('success');
  const [alertMessage, setAlertMessage] = useState('');
  const today = new Date();
  const annualLeaveDays = 15; // Kullanıcının yıllık izin hakkı
  const [rejectDialogOpen, setRejectDialogOpen] = useState(false);
  const [rejectReason, setRejectReason] = useState('');
  const [currentRow, setCurrentRow] = useState(null);
  const [messages, setMessages] = useState([]);

  const showMessage = (message, severityType = 'success') => {
    setMessages((prevMessages) => [
      ...prevMessages,
      { id: Date.now(), message, severity: severityType },
    ]);
  };

  const handleCloseMessage = (id) => {
    setMessages((prevMessages) => {
      //console.log('Before:', prevMessages);
      const updatedMessages = prevMessages.filter((msg) => msg.id !== id);
      //console.log('After:', updatedMessages);
      return updatedMessages;
    });
  };

  // Fetch data from Database
  useEffect(() => {    
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const data = await getContactDatas();
      setFormData(data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false);
    }
  };
  const columns = [
    { field: 'candidate_name', headerName: 'Candidate Name', flex: 2, minWidth: 140, align: 'center', headerAlign: 'center', },
    { field: 'email', headerName: 'Email', flex: 2, minWidth: 140, align: 'center', headerAlign: 'center', },
    { field: 'phone', headerName: 'Phone', flex: 2, minWidth: 140, align: 'center', headerAlign: 'center', },
    { field: 'message', headerName: 'Message', flex: 2, minWidth: 140, align: 'center', headerAlign: 'center', },
    { field: 'link', headerName: 'link', flex: 2, minWidth: 140, align: 'center', headerAlign: 'center', },
    { field: 'reject_description', headerName: 'Reject Description', flex: 2, minWidth: 140, align: 'center', headerAlign: 'center', },
    { field: 'atcreated', headerName: 'At Created', flex: 2, minWidth: 140, align: 'center', headerAlign: 'center', 
      valueGetter: (params) => {        
        const rawDate = params;  
        if (!rawDate) return "Invalid Date";
        const date = new Date(rawDate);
        return date.toLocaleString();
      },
    },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 2,
      minWidth: 200,
      align: 'center', headerAlign: 'center',
      renderCell: (params) => (
        <div>
          <button
            onClick={() => handleNewContactForm(params.row)}
            style={{ marginRight: '10px', height:'20px', color: 'green', 
            cursor: params.row.status === 2 ? 'not-allowed' : 'pointer' }}
            disabled={params.row.status === 2} // "Candidate List" butonu, status === 2 ise disabled
          >
            Candidate List
          </button>
          <button
            onClick={() => handleOpenRejectDialog(params.row)}
            style={{ color: 'red', 
              cursor: params.row.status === 2 ? 'not-allowed' : 'pointer' }}
            disabled={params.row.status === 2} // "Rejected" butonu, status === 2 ise disabled
          >
            Reject
          </button>
        </div>
      ),
    },
  ];

  const handleNewContactForm = async (row) => {
    try {
      console.log("Handle approve to new contact", row);
      const acknowledged = 0, follower = 0, status = 4, references = {"References": ["Web Mail Contact"]}; //const value for web contact
      const {email, candidate_name, phone, message, atcreated, link} = row;
      console.log("Contact row items: ", email, candidate_name, phone, message, atcreated, link, acknowledged, follower, status, references);     
      const response = await createNewWebStudent(email, candidate_name, phone, references);
      console.log("response.data.message: ", response.data.message);
      if (response.status === 200) {
        if(response.data.message === "There is a student record with the same email address!"){
          showMessage('There is a student record with the same email address!', 'warning');
        } else if(response.data.message === "New Student created successfully."){          
          const contact_status = 1; //1 = Save student table
          const id = row.id;
          await updateWebContact(id, contact_status);
          await fetchData();
          showMessage('The record has been updated  as New Contact successfully.', 'success');
        } 
        // else {
        //   showMessage('The record has been updated  as New Contact successfully.', 'success');
        // }
     } else {
      showMessage('Failed, The record has not been create as New Contact on candidate lists!', 'error');
    }
    } catch (error) {
      console.error('Error approving leave:', error);
    }finally{
      setRejectReason('');
    }
  };

  const handleOpenRejectDialog = (row) => {
    setCurrentRow(row);
    setRejectReason('');
    setRejectDialogOpen(true);
  };

  const handleCloseRejectDialog = () => {
    setRejectDialogOpen(false);
    setRejectReason('');
  };
  
  const updateWebContact = async(id, contact_status, rejectReason) =>{
    try {
      const response = await updateWebContactForms(id, contact_status, rejectReason);
      if (response.status === 200) {
        if(contact_status === 2){
          showMessage('The record has been updated successfully as REJECTED on web form list.', 'success');
        }
        else{
          showMessage('The record has been updated successfully  as New Student on web form list.', 'success');
        }
        fetchData();// Refresh DataGrid        
      } else {
        showMessage('Failed, The record has not been updated!', 'error');
      }
    } catch (error) {
      console.error('Error update web contact:', error);
    }
  };

  const handleReject = async (row) => {
    if (!currentRow) return;
    try {
      const status = 2; //rejected because database have a same record! Default 0 = New Record, 2 = Save student table, 1 = Rejected (yanlis mail veya ikintili aciklama)
      await updateWebContact(currentRow.id, status, rejectReason);
    } catch (error) {
      console.error('Error rejecting leave:', error);
    }finally{
      setRejectReason('');
      handleCloseRejectDialog();
    }
  };

  if (loading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '100vh',
        }}
      >
        <CircularProgress />
      </Box>
    );
  }
  return (
    <Box p={3}>
      <Box p={3} style={{ width: '100%' }}>
        <Typography variant="h5" gutterBottom>
          Web Contact Forms
        </Typography>
        <DataGrid
          rows={formData}
          columns={columns}
          pageSize={5}
          rowsPerPageOptions={[5]}
          sx={{
            '& .MuiDataGrid-row': {
              '&.row-status-2': {
                //backgroundColor: '#f0f4ff', // Hafif mavi arka plan
                backgroundColor: '#f8d7da', // light red
                //backgroundColor: '#fff9c4', // Açık sarı ton
                //backgroundColor: '#e3f2fd', // Buz mavisi ton
                color: '#333',
              },
              '&.row-status-1': {
                //backgroundColor: '#f0f4ff', // Hafif mavi arka plan
                backgroundColor: '#fff9c4', // Açık sarı ton
                //backgroundColor: '#fffde7',
                //backgroundColor: '#e3f2fd', // Buz mavisi ton
                color: '#333',
              },
            },
          }}
          autoHeight
          checkboxSelection
          onRowClick={(params) => setSelectedRow(params.row)} // Seçili satır bilgisi
          getRowId={(row) => row.id}
          getRowClassName={(params) =>params.row.status === 2 ? 'row-status-2' : params.row.status === 1 ? 'row-status-1': ''
          } // status === 2 için özel sınıf eklenir
          //disableColumnResize //kullanicinin tablo alanlarinin boyutunun degistirmesini engeller yazilmaz ise degistirebilir.
        />
      </Box>
      <Dialog open={rejectDialogOpen} onClose={handleCloseRejectDialog}>
        <DialogTitle>Reject Contact</DialogTitle>
        <DialogContent>
          <TextField
            label="Reason for Rejection"
            multiline
            rows={4}
            fullWidth
            value={rejectReason}
            onChange={(e) => setRejectReason(e.target.value)}
            inputProps={{ maxLength: 300 }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseRejectDialog} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleReject} color="primary" disabled={!rejectReason.trim()}>
            Reject
          </Button>
        </DialogActions>
      </Dialog>
      <MessageBox messages={messages} handleCloseMessage={handleCloseMessage} />
    </Box>    
  );
};

export default WebContactForms;