import axios from 'axios';
import config from '../../config';
import getToken from '../api/getToken';
const { BaseUrl } = config;

const updateNewInterviewTransaction = async (formData) => {
  try {
    console.log('updateNewInterviewTransaction: ', formData);
    const token = await getToken();
    const response = await axios.patch(`${BaseUrl}/students/updatenewinterviewtransaction`, 
      {id: formData},
      {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      }
    );
    return response.data;
  } catch (error) {  
      console.error("updateNewInterviewTransaction: ", error);
      return [];
  }
};

export default updateNewInterviewTransaction;