import axios from 'axios';
import config from '../../config';
import getToken from '../api/getToken';
const { BaseUrl } = config;

const createInterviewTransaction = async (formData) => {
  try {
   // console.log('createInterviewTransaction-Outcoming-formData: ', formData);
    const token = await getToken();
    const response = await axios.post(`${BaseUrl}/students/setinterviewtransaction`, 
      formData,
      {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      }
    );
    return response.data;
  } catch (error) {  
      console.error("Insert error: ", error);
      return [];
  }
};

export default createInterviewTransaction;


// import axios from 'axios';
// import config from '../../config';
// import getToken from '../api/getToken';
// const { BaseUrl } = config;

// const updateNewInterviewTask= async () => {
//     try {           
//         const token = await getToken(); // TODO: axios post istegimde params yerine data kullanilir
//         const response = await axios.put(`${BaseUrl}/student/updatenewinterviewtask`, 
//             formData,
//             {
//                 headers: {'Authorization': `Bearer ${token}`}
//             }
//         );  
//         return response.data;
//     } catch (error) {  
//         console.error("Insert error: ", error);
//         return [];
//     }
// };

// export default updateNewInterviewTask;